import axios from "axios";
export const apiGetStudents = (search: string) => axios.get(`/user/students?search=${search}`); 
export const apiGetStudentsAdvisor = (search: string) => axios.get(`/professor/advisor-students?search=${search}`); 
export const apiGetNotAdvisory = () => axios.get('/professor/advisor-students-new');
export const apiAddStudentAdvisory = (studentId: string) => axios.post('/professor/add-student-to-advisor', { studentId }); 
export const apiGetNotifications = () => axios.get('/user/notificationsForUser');
export const apiReadNotification = (notificationId: string) => axios.post('/user/readNotification', { notificationId });
export const apiRemoveStudentAdvisor = (studentId: string) => axios.post('/professor/remove-student', { studentId }); 
export const apiGetAllStudents = () => axios.get("/professor/allStudents");

