import React from "react";
import TextInput from "../../../../../Components/Form/TextInput";
import ButtonPrimary from "../../../../../Components/Button";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import {apiVerifyCode} from '../../../../../Api/auth';
type Inputs = {
  code: number;
  userId: string;
};

interface props {
  setStep: React.Dispatch<React.SetStateAction<number>>;
  userId: string
}
function Verification({ setStep, userId }: props) {
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<Inputs>({
    defaultValues: {
      code: 0,
      userId: userId,
    },
  });

  const onSubmit = (data: any) => {
    console.log(data);
    apiVerifyCode(data).then((res) => {
        setStep(3);
        }
    ).catch((err) => {
        console.log(err);
    }
    );
  };
  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <TextInput
          label="Enter your verification code"
          description="Enter the 6 digit code we sent to your email. If you did not receive the code, please click resend code."
          name="code"
          control={control}
          rules={{
            required: "Code is required",
          }}
          errorMessage={errors.code && errors.code.message}
        />
        <div className="mt-8">
          <ButtonPrimary type="submit">Verify</ButtonPrimary>
        </div>
      </form>
      {/* resend */}
      {/* <div className="mt-2">
        <Link
          className="font-semibold leading-6 text-indigo-600 hover:text-indigo-500"
          to={""}
          //   onClick={resendCode}
        >
          Resend code
        </Link>
      </div> */}
    </div>
  );
}

export default Verification;
