import React, { useState, Fragment } from "react";
import { Dialog, Menu, Transition } from "@headlessui/react";
import { NavLink, Link } from "react-router-dom";
import AuthHeader from "../AuthedHeader";
import { useSelector } from "react-redux";
import { logout } from "../../Redux/slices/user";
import { useAppDispatch } from "../../Redux/hooks";
import { XMarkIcon, XCircleIcon } from "@heroicons/react/24/solid";
import {
  BellIcon,
  Bars3Icon,
  ChevronDownIcon,
} from "@heroicons/react/24/outline";
import dayjs from "dayjs";
import { apiGetNotifications, apiReadNotification } from "../../Api/students";
import { Notification } from "./types";
import Modal from "../Modal";
import routes from "../../Routes";
import relativeTime from "dayjs/plugin/relativeTime";
dayjs.extend(relativeTime);

interface Iprops {
  children: React.ReactNode;
}
function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ");
}

function AuthedLayout({ children }: Iprops) {
  const dispatch = useAppDispatch();
  const user = useSelector((state: any) => state.user.user);
  const [sidebarOpen, setSidebarOpen] = useState<boolean>(false);
  const [notifications, setNotifications] = useState<Notification[]>([]);
  const [notification, SetNotification] = useState<Notification | undefined>(
    undefined
  );
  const [showNotifications, setShowNotifications] = useState(false);

  const handleNotificationClick = () => {
    apiGetNotifications()
      .then((notifications) => {
        setNotifications(notifications.data);
        setShowNotifications(!showNotifications);
      })
      .catch((error) => {
        console.error("Failed to get notifications:", error);
      });
  };

  const clickNotification = (notification: Notification) => {
    setShowModal(true);
    SetNotification(notification);
    apiReadNotification(notification._id)
      .then((readNotification) => {
        setShowNotifications(false);
      })
      .catch((error) => {
        console.error("Failed to read notification:", error);
      });
  };
  const [showModal, setShowModal] = useState(false);

  return (
    <>
      <div>
        <Transition.Root show={sidebarOpen} as={Fragment}>
          <Dialog
            as="div"
            className="relative z-50 lg:hidden"
            onClose={setSidebarOpen}
          >
            <Transition.Child
              as={Fragment}
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-900/80" />
            </Transition.Child>

            <div className="fixed inset-0 flex">
              <Transition.Child
                as={Fragment}
                enter="transition ease-in-out duration-300 transform"
                enterFrom="-translate-x-full"
                enterTo="translate-x-0"
                leave="transition ease-in-out duration-300 transform"
                leaveFrom="translate-x-0"
                leaveTo="-translate-x-full"
              >
                <Dialog.Panel className="relative mr-16 flex w-full max-w-xs flex-1">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-in-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in-out duration-300"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <div className="absolute left-full top-0 flex w-16 justify-center pt-5">
                      <button
                        type="button"
                        className="-m-2.5 p-2.5"
                        onClick={() => setSidebarOpen(false)}
                      >
                        <span className="sr-only">Close sidebar</span>
                        <XMarkIcon
                          className="h-6 w-6 text-white"
                          aria-hidden="true"
                        />
                      </button>
                    </div>
                  </Transition.Child>
                  {/* Sidebar component, swap this element with another sidebar if you like */}
                  <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-[#0E3368] px-6 pb-4 ring-1 ring-white/10">
                    <div className="flex h-16 shrink-0 items-center mt-2">
                      <div className="msj-logo h-10 w-8"></div>
                      <span className="text-white text-2xl ml-2 font-bold">
                        Scheduler
                      </span>
                    </div>
                    <>{user && <AuthHeader role={user.role} />}</>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition.Root>

        {/* Static sidebar for desktop */}
        <div className="hidden lg:fixed lg:inset-y-0 lg:z-30 lg:flex lg:w-72 lg:flex-col">
          {/* Sidebar component */}
          <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-[#0E3368] px-6 pb-4">
            <div className="flex h-16 shrink-0 mt-2 items-center">
              <div className="msj-logo h-10 w-8"></div>
              <span className="text-white text-2xl ml-2 font-bold">
                Scheduler
              </span>
            </div>
            <>{user && <AuthHeader role={user.role} />}</>
          </div>
        </div>

        <div className="lg:pl-72">
          <div className="sticky top-0 z-40 flex h-16 shrink-0 items-center gap-x-4 border-b border-gray-200 bg-white px-4 shadow-sm sm:gap-x-6 sm:px-6 lg:px-8">
            <button
              type="button"
              className="-m-2.5 p-2.5 text-gray-700 lg:hidden"
              onClick={() => setSidebarOpen(true)}
            >
              <span className="sr-only">Open sidebar</span>
              <Bars3Icon className="h-6 w-6" aria-hidden="true" />
            </button>

            {/* Separator */}
            <div
              className="h-6 w-px bg-gray-900/10 lg:hidden"
              aria-hidden="true"
            />

            <div className="flex flex-1 gap-x-4 self-stretch lg:gap-x-6">
              <div className="flex flex-1 gap-x-4 self-stretch lg:gap-x-6">
                <h1 className="flex items-center gap-x-2 text-lg font-medium leading-6 text-gray-900">
                  <span className="hidden lg:inline capitalize">
                    {/* {activeRoute} */}
                  </span>
                </h1>
              </div>

              <div className="flex items-center gap-x-4 lg:gap-x-6">
                {/* notification Disclosure */}
                <Menu as="div" className="relative">
                  <Menu.Button
                    className="-m-2.5 p-2.5 text-gray-400 hover:text-gray-500"
                    onClick={handleNotificationClick}
                  >
                    <span className="sr-only">View notifications</span>
                    <BellIcon className="h-6 w-6" aria-hidden="true" />
                  </Menu.Button>
                  <Transition
                    as={Fragment}
                    show={showNotifications}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items className="absolute right-0 z-10 mt-2.5 w-80 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-gray-900/5 focus:outline-none">
                      <div className="py-4">
                        <ul className="divide-y divide-gray-200">
                          {notifications ? (
                            notifications.slice(0,8).map((not) => (
                              <li
                                key={not._id}
                                className={`relative flex items-start justify-between px-4 py-2 text-sm leading-5 cursor-pointer ${
                                  !not.read ? "bg-gray-100" : ""
                                }`}
                                onClick={() => clickNotification(not)}
                              >
                                <div className="flex items-center">
                                  {!not.read && (
                                    <span className="h-2 w-2 bg-yellow-500 mr-2 rounded-full flex-shrink-0"></span>
                                  )}
                                  <div className="line-clamp-2 flex-grow pr-16">
                                    {not.description.trim().substring(0, 50)}
                                  </div>
                                </div>
                                <span className="text-xs text-gray-500 absolute top-2 right-2">
                                  {dayjs(not.createdAt).fromNow()}
                                </span>
                              </li>
                            ))
                          ) : (
                            <li className="px-4 py-2 text-sm leading-5">
                              No Notifications
                            </li>
                          )}
                        </ul>
                      </div>
                    </Menu.Items>
                  </Transition>
                </Menu>

                {/* Separator */}
                {showModal && (
                  <Modal show={showModal} onClose={() => setShowModal(false)}>
                    <div className="p-14 bg-white rounded-md shadow-md relative">
                      <XCircleIcon
                        className="h-8 w-8 text-gray-500 cursor-pointer hover:text-blue-900 absolute top-4 right-6"
                        onClick={() => setShowModal(false)}
                      />
                      {notification && (
                        <div>
                          <p className="text-xs text-gray-500 float-right">
                            {dayjs(notification.createdAt).format(
                              "MM/DD/YYYY hh:mm A"  
                            )}
                          </p>
                          <p className="text-xl font-bold mb-4">
                            {notification.title}
                          </p>
                          <p>{notification.description}</p>
                        </div>
                      )}
                    </div>
                  </Modal>
                )}
                <div
                  className="hidden lg:block lg:h-6 lg:w-px lg:bg-gray-900/10"
                  aria-hidden="true"
                />

                {/* Profile dropdown */}
                <Menu as="div" className="relative">
                  <Menu.Button className="-m-1.5 flex items-center p-1.5">
                    <span className="sr-only">Open user menu</span>
                    <img
                      className="h-8 w-8 rounded-full bg-gray-50"
                      src="https://api.dicebear.com/7.x/identicon/svg"
                      alt=""
                    />
                    <span className="hidden lg:flex lg:items-center">
                      <span
                        className="ml-4 text-sm font-semibold leading-6 text-gray-900"
                        aria-hidden="true"
                      >
                        {user?.username}
                      </span>
                      <ChevronDownIcon
                        className="ml-2 h-5 w-5 text-gray-400"
                        aria-hidden="true"
                      />
                    </span>
                  </Menu.Button>
                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items className="absolute right-0 z-10 mt-2.5 w-32 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none">
                      <Menu.Item>
                        <NavLink
                          to={`${routes.dashboard.profile}`}
                          className={classNames(
                            "block px-3 py-1 text-sm leading-6 text-gray-900"
                          )}
                          // activeClassName="bg-gray-50"
                        >
                          Profile
                        </NavLink>
                      </Menu.Item>
                      <Menu.Item>
                        {({ active }) => (
                          <Link
                            to={`/`}
                            onClick={() => dispatch(logout())}
                            className={classNames(
                              active ? "bg-gray-50" : "",
                              "block px-3 py-1 text-sm leading-6 text-gray-900"
                            )}
                          >
                            Sign out
                          </Link>
                        )}
                      </Menu.Item>
                    </Menu.Items>
                  </Transition>
                </Menu>
              </div>
            </div>
          </div>
          <main className="py-10">
            <div className="px-4 sm:px-6 lg:px-8">{children}</div>
          </main>
        </div>
      </div>
    </>
  );
}

export default AuthedLayout;
