import React from 'react';
import ButtonPrimary from '../../../../Components/Button';
import TextInput from '../../../../Components/Form/TextInput';
import { useForm } from 'react-hook-form';
import { useAppDispatch } from '../../../../Redux/hooks';
import { setSuccess } from '../../../../Redux/slices/layout';
import { apiChangePassword } from '../../../../Api/auth';
import { logout } from '../../../../Redux/slices/user';

interface IFormChangePassword {
  oldPassword: string;
  newPassword1: string;
  newpassword2: string;
}

function ChangePassword() {
  const dispatch = useAppDispatch();
  const { control, handleSubmit, watch, formState } = useForm<IFormChangePassword>({
    defaultValues: {
      oldPassword: '',
      newPassword1: '',
      newpassword2: ''
    }
  }); // Ensure to use useForm from react-hook-form



  const onSubmit = (data: IFormChangePassword) => {
    console.log(data)
    apiChangePassword(data).then(
      (res) => {
        //TODO: check if success is true to dispatch the following
        dispatch(setSuccess('Your password was updated successfully'));
       dispatch(logout());
      }
    ).catch((error) =>{
      console.log(error)
    })
  }

  const newPassword = watch('newPassword1', '');
  const confirmNewPassword = watch('newpassword2', '');

  const passwordMatch = newPassword === confirmNewPassword;

  return (
    <div className='mx-10 my-8'>
      <div className='flex justify-start py-2 mb-3'>
        <h1 className='text-2xl font-semibold text-gray-600'>
          Change Password
        </h1>
      </div> 
        <form className="mt-6" onSubmit={handleSubmit(onSubmit)}>
          <TextInput
            label="Current Password"
            name="oldPassword"
            description="Enter your current password"
            type="password"
            control={control}
            rules={{
              required: 'Current Password is required',
            }}
          />
          <TextInput
            label="New Password"
            name="newPassword1"
            description="Enter your new password"
            type="password"
            control={control}
            rules={{
              required: 'New Password is required',
              minLength: {
                value: 6,
                message: 'Password must be at least 6 characters',
              },
            }}
          />
          <TextInput
            label="Confirm New Password"
            name="newpassword2"
            description="Confirm your new password"
            type="password"
            control={control}
            rules={{
              required: 'Confirm New Password is required',
              validate: (value: any) =>
                value === newPassword || 'Passwords do not match',
            }}
          />
          {!passwordMatch && (
            <p className="text-red-500 text-sm mt-2">Passwords do not match</p>
          )}
          <div className="flex justify-start items-center mt-6">
          
            <ButtonPrimary type="submit">
              Submit
            </ButtonPrimary>
          </div>
        </form>
    </div>
  );
}

export default ChangePassword;
