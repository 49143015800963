import React,{useEffect} from "react";
import { IProfessorAppointmentsProps, IFormAppointment } from "../types";
import { useForm } from "react-hook-form";
import SelectBox from "../../../../../Components/Form/Select";
import TextInput from "../../../../../Components/Form/TextInput";
import ButtonPrimary from "../../../../../Components/Button";
import TextAreaInput from "../../../../../Components/Form/TextArea";
import { AppointmentStatus } from "../../../../../Utils/helpers";
import {apiUpdateAppointment} from '../../../../../Api/appointments'
import dayjs from "dayjs";
import { useAppDispatch } from "../../../../../Redux/hooks";
import { setSuccess } from "../../../../../Redux/slices/layout";

function UpdateAppointment({
  appointments,
  closeModal,
}: IProfessorAppointmentsProps) {
    const dispatch = useAppDispatch();
  const {control,
    handleSubmit,
    formState: { errors },
  } = useForm<IFormAppointment>({
    defaultValues: {
      appointmentId: appointments?._id,
      appointmentStatus: appointments?.status,
      appointmentLocation: appointments?.location, 
      summary: appointments?.summary,
    }, 
  });
console.log(appointments)  

  const onSubmit = (data: IFormAppointment) => {
    apiUpdateAppointment(data).then((res) => {
        console.log(res.data)
        dispatch(setSuccess(res.data.message)); 
        closeModal(false);
        }).catch((err) => {
        console.log(err)
    })
  };
  return (
    <div className="overflow-auto mx-14 my-14">
      <div className="flex justify-start py-2 mb-3">
        <h1 className="text-2xl font-semibold text-gray-600">
          Update Appointment
        </h1>
      </div>
      <div className="flex flex-col justify-start text-sm">
        <div className="py-1 sm:grid sm:grid-cols-3 sm:gap-2">
          <dt className="text-sm font-medium text-gray-500">Student</dt>
          <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
            {appointments?.studentName}
          </dd>
        </div>
        <div className="py-1 sm:grid sm:grid-cols-3 sm:gap-2">
          <dt className="text-sm font-medium text-gray-500">Guest</dt>
          <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
            {appointments?.guestName}
          </dd>
        </div>
        <div className="py-1 sm:grid sm:grid-cols-3 sm:gap-4">
          <dt className="text-sm font-medium text-gray-500">Reason</dt>
          <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
            {appointments?.reason}
          </dd>
        </div>
        <div className="py-1 sm:grid sm:grid-cols-3 sm:gap-2">
          <dt className="text-sm font-medium text-gray-500">Date</dt>
          <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
            {dayjs(appointments?.date).format("MMM  DD, YYYY")}
          </dd>
        </div>
        <div className="py-1 sm:grid sm:grid-cols-3 sm:gap-2">
          <dt className="text-sm font-medium text-gray-500">Start Time</dt>
          <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
            {dayjs(appointments?.startDateTime).format("hh:mm A")}
          </dd>
        </div>
      </div>
      <div className="mt-4">
        <form className="space-y-6" onSubmit={handleSubmit(onSubmit)}>
          <div>
            <SelectBox
              label="Status"
              name="appointmentStatus"
              description="Choose the status of the appointment"
              control={control}
              rules={{
                required: "Required",
              }}
              errorMessage={
                errors.appointmentStatus && errors.appointmentStatus.message
              }
              options={
                <>
                  <option value="">Select an option</option>
                  {Object.values(AppointmentStatus).map((status) => (
                    <option key={status} value={status}>
                      {status}
                    </option>
                  ))}
                </>
              }
            />
          </div>
          <div>
            <TextInput
              label="Location"
              name="appointmentLocation"
              description="Enter the office location eg. 213 SOC"
              control={control} 
              rules={{
                required: "Required",
              }}
              errorMessage={
                errors.appointmentLocation && errors.appointmentLocation.message
              }
            />
          </div>
          <div>
            <TextAreaInput
              label="Summary"
              name="summary"
              description="Enter the summary notes"
              control={control}
              rules={{
                required: false,
              }}
              errorMessage={
                errors.summary && errors.summary.message
              }
            />
          </div>
          <div className="flex justify-end">
            <ButtonPrimary
              type="button"
              className="bg-white mr-4 text-black border hover:bg-gray-100 font-bold py-2 px-8 rounded-full "
              onPress={() => closeModal(false)}
            >
              Cancel
            </ButtonPrimary>
            <ButtonPrimary type="submit">Save</ButtonPrimary>
          </div>
        </form>
      </div>
    </div>
  );
}

export default UpdateAppointment;
