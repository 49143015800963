import React from 'react'
import Authed from './Routes/Authed'
import Guest from './Routes/Guest'
import { useAppSelector } from './Redux/hooks'

function Gate() {
  const user =  useAppSelector((state: any) => state.user)
  return (
    <>
    {user?.user ? <Authed /> : <Guest />}
    </>
  )
}

export default Gate
