import React from "react";
import dayjs from "dayjs";
import ButtonPrimary from "../../../../../Components/Button";
import { useForm } from "react-hook-form";
import { useAppSelector, useAppDispatch } from "../../../../../Redux/hooks";
import { AvailbiliyProps, User, FormAvailability, TimeSlots } from "../types";
import { TrashIcon } from "@heroicons/react/24/solid";
import { apiUpdateAvailability } from "../../../../../Api/availability";
import Timepicker from "../../../../../Components/Form/DatePicker/TimePicker";
import { setSuccess } from "../../../../../Redux/slices/layout";
import { ArchiveBoxXMarkIcon } from "@heroicons/react/24/outline";

function UpdateAvailability({ data, closeModal }: AvailbiliyProps) {
  const dispatch = useAppDispatch();
  const { user } = useAppSelector((state) => state.user) as { user: User };
  const {
    control,
    setValue,
    getValues,
    handleSubmit,
    formState: { errors },
  } = useForm<FormAvailability>({
    defaultValues: {
      professorId: user?._id,
      availabilityId: data?._id,
      date: new Date(data?.date),
      weekDay: data?.weekDay,
      removeTimeSlots: [],
      newTimeSlots: [
        {
          startTime: new Date(data?.date),
          endTime: new Date(data?.date),
        },
      ],
    },
  });

  const handleDeleteTimeSlot = (timeSlot: TimeSlots, index: number) => {
    const removeTimeSlots = getValues("removeTimeSlots");
    removeTimeSlots.push({
      startTime: timeSlot.startTime,
      endTime: timeSlot.endTime,
    });
    setValue("removeTimeSlots", removeTimeSlots);
    const formData = {
      professorId: user?._id,
      availabilityId: data?._id,
      date: data?.date,
      weekDay: data?.weekDay,
      removeTimeSlots: removeTimeSlots,
    };
    console.log(formData, "formData");
    apiUpdateAvailability(formData)
      .then((res) => {
        dispatch(setSuccess("Successfully Deleted time slots"));
        closeModal(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div className="overflow-auto mx-14 my-14">
      <div className="flex justify-start py-2">
        <h1 className="text-2xl font-semibold text-gray-600">
          Update Availability
        </h1>
      </div>
      <div className="mt-4">
        {/* weekday and date  display*/}
        <div className="flex justify-start">
          <div className="flex flex-col justify-start border bg-gray-50 py-6 px-6 rounded-md">
            <h1 className="text-lg font-bold text-gray-600 uppercase">
              {data.weekDay}
            </h1>
            <h1 className="text-sm font-semibold text-gray-600">
              {dayjs(data.date).format("MMM DD, YYYY")}
            </h1>
            {/* <h1 className="text-sm font-semibold text-gray-600 ml-2">
              {data.date}
            </h1> */}
          </div>
        </div>
        {/* set your weekly hr title */}
        <div className="flex justify-between items-center">
          {/* <h1 className="text-sm font-semibold text-gray-600">
            Available Time Slots
          </h1> */}
        </div>
        <>
          {data?.timeSlots?.length === 0 ? (
            <>
              <div className="flex justify-between items-center mt-4">
                <h1 className="text-sm font-semibold text-gray-600">
                  Available Time Slots
                </h1>
              </div>
              <hr className="w-full mt-2" />
              <div className="flex flex-col justify-center items-center mt-10">
                <ArchiveBoxXMarkIcon className="h-8 w-8 mb-2 text-gray-400" />
                <h1 className="text-sm font-semibold text-gray-600">
                  No Time Slots for {dayjs(data.date).format("MMM DD, YYYY")}
                </h1>
              </div>
            </>
          ) : (
            <div className="my-4 overflow-y-auto max-h-[20rem]">
              <h1 className="text-sm font-semibold text-gray-600">
                Available Time Slots
              </h1>
              {/* description */}
              <p className="text-xs text-gray-600 mt-2">
                Click the trash icon to delete a time slot from your
                availability.
              </p>
              <hr className="w-full mt-2" />
              {data?.timeSlots?.map((timeSlot, index) => (
                <div className="w-1/2 my-4" key={index}>
                  <div className="flex justify-between items-center mt-4">
                    <div className="flex justify-start items-center">
                      <h1 className="text-sm text-gray-600">
                        {dayjs(timeSlot.startTime).format("hh:mm A")}
                      </h1>
                      <h1 className="text-sm text-gray-600 ml-2">-</h1>
                      <h1 className="text-sm text-gray-600 ml-2">
                        {dayjs(timeSlot.endTime).format("hh:mm A")}
                      </h1>
                    </div>
                    <div className="flex justify-end">
                      <button
                        className="text-gray-500 hover:text-gray-900"
                        onClick={() => handleDeleteTimeSlot(timeSlot, index)}
                      >
                        <TrashIcon className="h-6 w-6 ml-2 text-blue-900" />  
                      </button>
                    </div>
                  </div>
                  <hr className="w-full mt-2" />
                </div>
              ))}
            </div>
          )}
        </>
        {/* add time slots */}
        <form
          onSubmit={handleSubmit((data: FormAvailability) => {
            const newTimeSlots = getValues("newTimeSlots");
            newTimeSlots.push({
              startTime: new Date(data?.startTime),
              endTime: new Date(data?.endTime),
            });
            setValue("newTimeSlots", newTimeSlots);
            apiUpdateAvailability(data)
              .then((res) => {
                dispatch(setSuccess("Successfully Updated time slots"));
                closeModal(false);
              })
              .catch((err) => {
                console.log(err);
              });
          })}
          className="my-2"
        >
          <div className="flex flex-col justify-start my-8">
            <h1 className="text-sm font-semibold text-gray-600">
              Add Time Slot
            </h1>
            <p className="text-xs text-gray-600 mt-2">
              Add a new time slot to your availability of the day selected
              above.
            </p>
            <hr className="w-full mt-2 mb-4" />
            <Timepicker
              control={control}
              name="newTimeSlots[0]"
              errorMessage="Please select a time slot"
              rules={{ required: true }}
              label="Time"
              defaultDate={new Date(data?.date)}
            />
          </div>
          <div className="flex justify-end items-center">
            <ButtonPrimary
              type="button"
              className="bg-white mr-4 text-black border hover:bg-gray-100 font-bold py-2 px-8 rounded-full "
              onPress={() => closeModal(false)}
            >
              Cancel
            </ButtonPrimary>
            <ButtonPrimary type="submit">Submit</ButtonPrimary>
          </div>
        </form>
      </div>
    </div>
  );
}

export default UpdateAvailability;
