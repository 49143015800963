import React from 'react';
import { Controller } from 'react-hook-form';
import {default as CustomDatePicker} from "react-datepicker";
import { CalendarDaysIcon } from '@heroicons/react/24/solid';

type DatePickerProps = {
    label: string;
    description?: string;
    errorMessage?: string;
    name: string;
    rules?: any;
    control: any;
   
};
const DatePicker = (props: DatePickerProps) => {
    const { label, description, errorMessage, name, rules, control } = props;

    return (
        <Controller
            control={control}
            name={name}
            rules={rules}
            render={({ field, fieldState }) => (
                <div className="mt-4">
                    <label className="block text-sm font-medium leading-6 text-gray-900">
                        {label}
                    </label>
                    {description && (
                        <p className="text-xs italic">
                            {description}
                        </p>
                    )}
                    <div className="relative mt-2 mb-2">
                        <CustomDatePicker
                            className="px-2 block w-full border-1.5 bg-gray-50 rounded-md shadow-sm border-gray-200 py-2 text-gray-900 sm:text-sm sm:leading-6 focus:outline-none focus:border-indigo-600 focus:ring-indigo-600"
                            selected={field.value}
                            onChange={field.onChange}
                            dateFormat="MMMM d, yyyy"
                            placeholderText="Select a date"
                            showIcon 
                            icon={<CalendarDaysIcon className="h-8 w-8 mr-2 p-2  text-gray-500" />}  
                            
                        />  

                    </div>

                    {fieldState.error && (
                        <p className="text-red-500 mt-2 text-xs italic">
                            {errorMessage}
                        </p>
                    )}
                </div>
            )}
        />
    );
};

export default DatePicker;