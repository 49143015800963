import React,{useState} from 'react'
import TextInput from '../../../../../Components/Form/TextInput'
import ButtonPrimary from '../../../../../Components/Button'
import { useForm } from 'react-hook-form'
import Modal from '../../../../../Components/Modal'
import {apiResetPassword} from '../../../../../Api/auth';
import { useNavigate } from 'react-router-dom'
interface Iinputs {
    newPassword1: string;
    newPassword2: string;
}

function ChangePassword() { 
    const navigate = useNavigate();
const [success, setSuccess] = useState<boolean>(false);
    const {
        handleSubmit,
        control,
        formState: { errors },
      } = useForm<Iinputs>(
        {
          defaultValues: {
            newPassword1: "",
            newPassword2: "",
          },
        } 
      );
    
      const onSubmit = (data: any) => {
        apiResetPassword(data).then((res) => {
            setSuccess(true);
        }
        ).catch((err) => {
            console.log(err);
        }
        );
      };
  return (
    <div>
        <p className="text-sm text-gray-500">Enter your new password</p> 
        <form  onSubmit={handleSubmit(onSubmit)}>
            <TextInput
                label="Password"
                name="newPassword1" 
                type = "password"
                control={control}
                rules={{
                required: "Password is required",
                }}
                errorMessage={errors.newPassword1 && errors.newPassword1.message}
            />
            <TextInput
                label="Confirm Password"
                name="newPassword2"
                type='password' 
                control={control}
                rules={{
                required: "Confirm Password is required",
                }}
                errorMessage={errors.newPassword2 && errors.newPassword2.message}
            />
            <div className="mt-8">
                <ButtonPrimary type="submit">Change Password</ButtonPrimary>
            </div>
        </form>
        {success && (
            <Modal
                show={success}
                maxWidth='xl'     
                onClose={() => setSuccess(false)}
            >
                <div className="text-center py-4">  
                    <h2 className="mt-6 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
                        Congratulations! 
                    </h2>
                    <p className=" text-gray-900"> 
                        Your password has been changed successfully! <br/>
                        You can now login to your account.
                    </p>
                    <div className="mt-8 mb-6">
                        <ButtonPrimary type="button" onPress={() => navigate('/')}>Login</ButtonPrimary>
                    </div>
                </div>
            </Modal>
        )}
    </div>
  )
}

export default ChangePassword
