import { Student } from "../types";
import { UserGroupIcon, UserCircleIcon, XCircleIcon } from "@heroicons/react/24/solid";
interface Props {
  course: any;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
}
function ViewCourse({ course, setShowModal }: Props) {
  const handleClose = () => {
    setShowModal(false);
  }
  return (
    <>
      {course && (
        <div className="overflow-auto mx-14 my-14"> 
          <div className="flex justify-end">
            <button className="text-gray-500 text-bold hover:text-gray-900" onClick={handleClose}>
              <XCircleIcon className="h-8 w-8" />  
            </button>
          </div>
          <div className="flex justify-start py-2">
            <h1 className="text-2xl font-semibold text-gray-600 capitalize">
              {course.courseName} - {course.courseCode}
             <p className="text-xs italic font-semibold text-gray-600">by Professor {course.professorName}</p>   
            </h1>
          </div>
          <div className="flex flex-col justify-start py-2">
            <h1 className="text-sm font-semibold text-gray-600"> 
              Course Department
            </h1>
            <p className="text-sm my-1 text-gray-600">{course.courseDepartment}</p>
          </div>
          <hr className="w-full mt-2" />
          <div className="flex flex-col justify-start py-2">
            <h1 className="text-sm font-semibold text-gray-600"> 
              Course Description
            </h1>
            <p className="text-sm my-1 text-gray-600">{course.courseDescription}</p> 
          </div>
          <hr className="w-full mt-2" />
          <div className="flex justify-start pt-2">
            <h1 className="text-sm font-semibold text-gray-600">
              Students Enrolled
            </h1>
          </div>
          {course.students.length > 0 ? (
            <div className="my-4 overflow-y-auto max-h-[20rem]"> 
              <div className="flex flex-col">
                {course.students.map((student: Student) => (
                  <div key={student._id}
                    className="flex text-sm text-gray-600 items-center my-1 mx-2"
                  >
                    <UserCircleIcon className="h-5 w-5 mr-2 text-gray-400" /> 
                    <div className="mr-2">
                      {student.firstName} {student.lastName}
                    </div>
                    {student.grade && (
                      <span className="bg-yellow-500 text-yellow-800  text-xs font-medium me-2 px-2.5 py-0.5 rounded-lg">
                        {student.grade}
                      </span>
                    )}
                  </div>
                ))}
              </div>
            </div>
          ) : (
            <div className="flex flex-col justify-center items-center mt-10">
              <UserGroupIcon className="h-8 w-8 mb-2 text-gray-400" />
              <h1 className="text-sm font-semibold text-gray-600">
                No students in this course yet.
              </h1>
            </div>
          )}
        </div>
      )}
    </>
  );
}

export default ViewCourse;
