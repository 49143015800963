import React,{useState, useEffect} from 'react'
import {apiStudentUpcoming} from '../../../../Api/overview'
import { useAppSelector } from '../../../../Redux/hooks'
function OverviewStudent() {
  const user = useAppSelector((state: any) => state.user.user);
  const [upcoming, setUpcoming] = useState<any>(null);
  console.log(user)

useEffect(() => {
  apiStudentUpcoming()
    .then((res) => {
      setUpcoming(res.data)
    })
    .catch((err) => {
      console.log(err)
    })
}, []) 
  return (
    <div>
      <div>
      <div className="flex justify-between items-center mb-4">
        <h1 className="text-2xl font-semibold text-gray-600">
          Good Morning, {user?.firstName}
        </h1>
      </div>
      <div className="flex space-x-6 my-10 pb-10">
        <div className="bg-white w-full rounded-lg shadow p-8">
          <div className="h-[400px]">
            <h1 className="font-bold capitalize">
              Today's Appointment
            </h1>
            {upcoming && (
            <div className="flex space-x-4">
              <div className="flex flex-col space-y-2">
                <p className="text-2xl text-gray-400"> Array contains {upcoming?.length} Appointments duh!</p>
              </div>
              </div>
            )}
          </div>
        </div>
        <div className="w-1/2 space-y-4">
          <div className="bg-white h-1/2 shadow rounded-lg p-4">
            <h1 className="font-bold capitalize">
              Upcoming Appointments
            </h1>
            <p className="text-sm text-gray-400"> Next Appointments</p>
          </div>
          <div className="bg-white h-1/2 shadow rounded-lg p-4">
            <h1 className="font-bold capitalize">
              Pending Appointments
            </h1>
            <p className="text-sm text-gray-400"> Appointments to be approved</p> 
          </div>
        </div>
      </div>
    </div>
    </div>
  )
}

export default OverviewStudent
