import { useState } from "react";
import {TrashIcon,EyeIcon, PencilSquareIcon} from "@heroicons/react/24/solid";
import Modal from "../../../../../Components/Modal";
import Confirm from "../../../../../Components/Confirm";
import { apiDeleteCourse } from "../../../../../Api/courses";
import ViewCourse from "../View";
import { useAppDispatch } from "../../../../../Redux/hooks";
import { setSuccess } from "../../../../../Redux/slices/layout";
import UpdateCourse from "../Update";

export const CourseColumns = [
  {
    Header: "Course ID",
    accessor: "courseCode",
  },
  {
    Header: "Course Name",
    accessor: "courseName",
  },
  {
    Header: "Course Description",
    accessor: "courseDescription",
    Cell: ({ value }: any) => {
      return (
        <div style={{ whiteSpace: "pre-line" }} className="break-words">
          {value}
        </div>
      );
    },
  },
  {
    Header: "Actions",
    accessor: "actions",
    Cell: ({ row }: any) => {
      const dispatch = useAppDispatch();
      const [showModal, setShowModal] = useState<boolean>(false);
      const [confirm, setConfirm] = useState(false);
      const [displayModal, setDisplayModal] = useState("");
      const handleDelete = () => {
        apiDeleteCourse(row.original._id)
          .then((res) => {
            dispatch(setSuccess("Course delete successfully"));
          })
          .catch((err) => console.log(err));
      };

      return (
        <>
          <div className="flex justify-start items-center">
            <button
              className="text-gray-500 hover:text-gray-900"
              onClick={() => {
                setShowModal(true);
                setDisplayModal("view");
              }}
            >
              <EyeIcon className="h-5 w-5 mr-2" />
              View 
            </button>
            <button
              className="text-gray-500 hover:text-gray-900 ml-2"
              onClick={() => {
                setShowModal(true);
                setDisplayModal("edit");
              }}
            >
              <PencilSquareIcon className="h-5 w-5 mr-2" />
              Edit
            </button>
            <button
              className="text-gray-500 hover:text-gray-900 ml-2"
              onClick={() => setConfirm(true)}
            >
              <TrashIcon className="h-5 w-5 mr-2" />
              Delete
            </button>
          </div>
          {showModal && (
            <Modal show={showModal} onClose={() => setShowModal(false)}>
              {displayModal === "view" ? (
                <ViewCourse course={row.original} setShowModal={setShowModal} />
              ) : (
                <UpdateCourse data={row.original} showModal={setShowModal} /> 
              )}
            </Modal>
          )}
          {confirm && (
            <Confirm
              title="Delete Course"
              onClose={() => setConfirm(false)}
              onConfirm={() => handleDelete()}
            >
              Are you sure you want to remove this course?
            </Confirm>
          )}
        </>
      );
    },
  },
];
