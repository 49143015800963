import React from "react";
import { Text, TextField, Label, TextArea } from "react-aria-components";
import { Controller } from "react-hook-form";

interface ITextInput {
  label: string;
  description?: string;
  errorMessage?: string;
  name: string;
  rules?: any;
  control: any;
  type?: string;
}
const TextAreaInput = (props: ITextInput) => {
  const { label, description, errorMessage, name, rules, control } = props;
  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      render={({ field, fieldState }) => (
        <TextField {...props} className="mt-4">
          <Label className="block text-sm font-medium leading-6 text-gray-900">
            {label}
          </Label>
          {description && (
            <Text className="text-xs italic" slot="description">
              {description}
            </Text>
          )}
          <div className="relative mt-2 mb-2">
            <TextArea
              className=" px-2 block w-full border-1.5 bg-gray-100 rounded-md shadow-sm border-gray-400 py-2 text-gray-900  sm:text-sm sm:leading-6 focus:outline-none focus:border-indigo-600 focus:ring-indigo-600"
              value={field.value}
              onChange={(e) => field.onChange(e.target.value)}
              cols={20}
              rows={5}
            />
          </div>

          {fieldState.error && (
            <Text
              className="text-red-500 mt-2 text-xs italic"
              slot="errorMessage"
            >
              {errorMessage}
            </Text>
          )}
        </TextField>
      )}
    />
  );
};

export default TextAreaInput;
