import React from 'react';
import { Controller } from 'react-hook-form';
import DatePicker from "react-datepicker"; 
import { CalendarDaysIcon } from '@heroicons/react/24/solid';
import "react-datepicker/dist/react-datepicker.css";
import './styles.m.scss' 

interface TimeRangeSelectorProps {
  control: any;
  name: string;
  rules?: any;
  label: string;
  description?: string;
  errorMessage?: string;
  defaultDate: Date;
}

const TimePicker = (props: TimeRangeSelectorProps) => { 
  const { label, description, errorMessage, name, rules, control, defaultDate } = props;
  return (
    <div>
      <Controller
        name={`${name}.startTime`} 
        control={control}
        defaultValue={defaultDate}  
        rules={rules}
        render={({ field }) => ( 
          <div> 
            <label className="text-sm font-medium leading-6 text-gray-900">Start {label} </label>
            <DatePicker
              className="px-2 block w-full border-1.5 bg-gray-100  rounded-md shadow-sm border-gray-200 py-2 text-gray-900 sm:text-sm sm:leading-6 focus:outline-none focus:border-indigo-600 focus:ring-indigo-600"
              popperPlacement='right'   
              popperModifiers={[
                {
                  name: "offset",
                  options: {
                    offset: [4, 10],  
                  },
                },
                {
                  name: "preventOverflow",
                  options: {
                    rootBoundary: "viewport",
                    tether: false,
                    altAxis: true,
                  },
                },
              ]}  
              showIcon
              icon={<CalendarDaysIcon className="h-8 w-8 text-gray-500" />}  
              selected={field.value}
              onChange={(date: Date) => field.onChange(date)}  
              showTimeSelect
              timeFormat="hh:mm aa" 
              timeIntervals={30}
              timeCaption="time"
              dateFormat="MMM d, yyyy h:mm aa"
              startDate={defaultDate}  
            />
          </div>
        )}
      />

      <Controller
        name={`${name}.endTime`}
        control={control}
        defaultValue={defaultDate} 
        rules={rules}
        render={({ field }) => (
          <div className='mt-2 '> 
            <label className="text-sm font-medium leading-6 text-gray-900"> End {label} </label>
            <DatePicker
              className="px-2 block w-full border-1.5 bg-gray-100 rounded-md shadow-sm border-gray-200 py-2 text-gray-900 sm:text-sm sm:leading-6 focus:outline-none focus:border-indigo-600 focus:ring-indigo-600"
              popperPlacement='right'    
              popperModifiers={[
                {
                  name: "offset",
                  options: {
                    offset: [4, 10],  
                  },
                },
                {
                  name: "preventOverflow",
                  options: {
                    rootBoundary: "viewport",
                    tether: false,
                    altAxis: true,
                  },
                },
              ]}  
              showIcon
              icon={<CalendarDaysIcon className="h-8 w-8 text-gray-500" />}   
              selected={field.value}
              onChange={(date: Date) => field.onChange(date)}  
              showTimeSelect
              timeFormat="hh:mm aa" 
              timeIntervals={30}
              timeCaption="time"
              dateFormat="MMM d, yyyy h:mm aa"
            />
          </div>
        )} 
      />
    </div>
  );
};

export default TimePicker; 