//created by Hannah
import React, { useState, useEffect, useMemo } from "react";
import { useForm } from "react-hook-form";
import ButtonPrimary from "../../../../Components/Button";
import { apiAddStudentAdvisory, apiGetNotAdvisory, apiGetStudentsAdvisor } from "../../../../Api/students";
import Table from "../../../../Components/Table";
import { StudentColumns } from "./Columns";
import { ArchiveBoxXMarkIcon } from "@heroicons/react/24/outline";
import TextInput from "../../../../Components/Form/TextInput";
import Modal from "../../../../Components/Modal";
import SearchableSelectBox from "../../../../Components/SearchSelect";
import { useDispatch } from "react-redux";
import { setSuccess } from "../../../../Redux/slices/layout";

function Advisory() {
  const [students, setStudents] = useState([]);
  const columns = useMemo(() => StudentColumns, []);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const { control, handleSubmit, watch } = useForm();
  const [newStudents,setNewStudents] = useState<{ label:string, value:string}[]>([]);
  const [selected,selectedOption] = useState<string>('');
  const dispatch = useDispatch();

 

  const getStudents = (search:string="") => {
  
    apiGetStudentsAdvisor(search)
      .then((res) => {
        setStudents(res.data.data.students);
      })
      .catch((err) => {
        console.log(err);
      });
  };


  const handleSearch = (data: any) => {
    const searchValue = data.Search;
  };

  useEffect(() => {
    const searchValue = watch("Search");
    getStudents(searchValue);

  }, [watch("Search")]);

  const handleAddStudent = async () => {
      const response = await apiGetNotAdvisory();
      const newStudentsData = response.data;
  
      // Map over newStudentsData and create a new array of objects with label and value
      const mappedStudents = newStudentsData.map((student:any) => ({
        label: `${student.firstName} ${student.lastName}`,
        value: student._id.toString(), // Assuming Id is a number, convert to string if needed
      }));
  
      // Update the newStudents state with the mappedStudents array
      setNewStudents(mappedStudents);
  
      setIsModalOpen(true);
  
  };


  const handleCloseModal = () => {
    setIsModalOpen(false); // Close the modal
    selectedOption("");
  };
 
  const onSearchSubmit: any = async () => {
    console.log('page',selected);
    // Handle form submission
    
    if(selected){
    let addStudent = await apiAddStudentAdvisory(selected);
    if(addStudent.data){
      setIsModalOpen(false);
      dispatch(setSuccess('The student was addedd successfully'));

      getStudents();
      
      handleCloseModal();


      }
    }
  };

  const onStudentRemoved  = async () => {
    dispatch(setSuccess('The student was removed successfully'));
    getStudents();
  };


  return (
    <div>
      <div className="flex justify-between items-center">
        <h1 className="text-2xl font-semibold text-gray-600">Advisory</h1>
        <ButtonPrimary onPress={handleAddStudent}>Add Student</ButtonPrimary>

      </div>
      <div>
        <form onSubmit={handleSubmit(handleSearch)}>
          <div className="w-[35%]">
          <TextInput label="Search" name="Search" control={control} />
          </div>
        </form>
      </div>
      <div className="mt-6">
        {Array.isArray(students) && students.length > 0 ? ( 
        <Table columns={columns({ onStudentRemoved })} data={students} />
        ) : (
          <div className="flex flex-col justify-center items-center mt-40">
            <ArchiveBoxXMarkIcon className="h-10 w-10 text-gray-400" />
            <h1 className="font-semibold text-gray-600">
              No students found
            </h1>
          </div>
        )}
      </div>
      <Modal show={isModalOpen} onClose={handleCloseModal}>
      <div className="m-4 pb-[200px]">
      <div className="mb-4">
        <h1 className="text-2xl font-semibold text-gray-600">Add Student</h1>
      </div>

      <div className="mb-6">
        <SearchableSelectBox
        onSelect={selectedOption}
          label="Select a Student"
          //name="selectedOption"
          //control={control}
          
          options={newStudents}
        />
      </div>

      <div className="flex justify-end">
        <ButtonPrimary onPress={onSearchSubmit}>Add Student</ButtonPrimary>
      </div>
    </div>
</Modal>
    </div>
  );
}

export default Advisory;