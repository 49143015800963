// reuable react-table component
import React from "react";
import {
  useTable,
  usePagination,
  useSortBy,
  TableInstance,
  TableState,
} from "react-table";
import { JSX } from "react/jsx-runtime";
import {
  ChevronLeftIcon,
  ChevronRightIcon,
  ChevronDoubleRightIcon,
  ChevronDoubleLeftIcon,
} from "@heroicons/react/20/solid";

interface Props {
  columns: any;
  data: any;
}
interface TableProps extends TableInstance {
  headerGroups: any[];
  prepareRow: (row: any) => void;
  page: any[];
  canPreviousPage: boolean;
  canNextPage: boolean;
  pageOptions: any[];
  pageCount: number;
  gotoPage: (arg0: number) => void;
  nextPage: () => void;
  previousPage: () => void;
  state: TableState & {
    pageIndex: number;
    pageSize: number;
    sortBy: any[];
    filters: any[];
    groupBy: any[];
    expanded: any[];
    selectedRowIds: any[];
    skipPageReset: boolean;
  };
}

function Table({ columns, data }: Props) {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    // setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, pageSize: 10 } as Partial<TableState>,
    },
    useSortBy,
    usePagination
  ) as TableProps;

  // Render the UI for your table
  return (
    <>
      <div className="mt-2 flex flex-col">
        <div className="-my-2 overflow-x-auto -mx-4 sm:-mx-6 lg:-mx-8">
          <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
            <table
              {...getTableProps()}
              className="min-w-full divide-y divide-gray-200 rounded-lg overflow-hidden"  
            >
              <thead className="bg-gray-100"> 
                {headerGroups.map(
                  (headerGroup: {
                    getHeaderGroupProps: () => JSX.IntrinsicAttributes &
                      React.ClassAttributes<HTMLTableRowElement> &
                      React.HTMLAttributes<HTMLTableRowElement>;
                    headers: any[];
                  }) => (
                    <tr
                      {...headerGroup.getHeaderGroupProps()}
                      className="border-b border-gray-200"
                    >
                      {headerGroup.headers.map((column) => (
                        <th
                          {...column.getHeaderProps(
                            column.getSortByToggleProps()
                          )}
                          className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                        >
                          {column.render("Header")}
                          <span>
                            {column.isSorted
                              ? column.isSortedDesc
                                ? " 🔽"
                                : " 🔼"
                              : ""}
                          </span>
                        </th>
                      ))}
                    </tr>
                  )
                )}
              </thead>
              <tbody
                {...getTableBodyProps()}
                className="bg-white divide-y divide-gray-200"
              >
                {page.map(
                  (
                    row: {
                      getRowProps: () => JSX.IntrinsicAttributes &
                        React.ClassAttributes<HTMLTableRowElement> &
                        React.HTMLAttributes<HTMLTableRowElement>;
                      cells: {
                        getCellProps: () => JSX.IntrinsicAttributes &
                          React.ClassAttributes<HTMLTableDataCellElement> &
                          React.TdHTMLAttributes<HTMLTableDataCellElement>;
                        render: (
                          arg0: string
                        ) =>
                          | string
                          | number
                          | boolean
                          | React.ReactPortal
                          | React.ReactElement<
                              any,
                              string | React.JSXElementConstructor<any>
                            >
                          | Iterable<React.ReactNode>
                          | null
                          | undefined;
                      }[];
                    },
                    i: any
                  ) => {
                    prepareRow(row);
                    return (
                      <tr {...row.getRowProps()} className="hover:bg-gray-100">
                        {row.cells.map(
                          (cell: {
                            getCellProps: () => JSX.IntrinsicAttributes &
                              React.ClassAttributes<HTMLTableDataCellElement> &
                              React.TdHTMLAttributes<HTMLTableDataCellElement>;
                            render: (
                              arg0: string
                            ) =>
                              | string
                              | number
                              | boolean
                              | React.ReactElement<
                                  any,
                                  string | React.JSXElementConstructor<any>
                                >
                              | Iterable<React.ReactNode>
                              | React.ReactPortal
                              | null
                              | undefined;
                          }) => {
                            return (
                              <td
                                {...cell.getCellProps()}
                                className="px-6 py-4 whitespace-nowrap text-sm text-gray-500"
                              >
                                {cell.render("Cell")}
                              </td>
                            );
                          }
                        )}
                      </tr>
                    );
                  }
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      {/* 
        Pagination can be built however you'd like. 
        This is just a very basic UI implementation:
        */}
      <div className="px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6">
        <div className="flex-1 flex justify-between sm:hidden">
          <button
            onClick={() => previousPage()}
            disabled={!canPreviousPage}
            className="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
          >
            Previous
          </button>
          <button
            onClick={() => nextPage()}
            disabled={!canNextPage}
            className="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
          >
            Next
          </button>
        </div>
        <div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
          <div>
            <p className="text-sm text-gray-700">
              Showing
              <span className="font-medium"> {pageIndex + 1} </span>
              to
              <span className="font-medium"> {pageSize} </span>
              of
              <span className="font-medium"> {pageOptions.length} </span>
              results
            </p>
          </div>
          <div>
            <nav
              className="relative z-0 inline-flex rounded-md shadow-sm -space-x-px"
              aria-label="Pagination"
            >
              <button
                onClick={() => gotoPage(0)}
                disabled={!canPreviousPage}
                className="relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
              >
                <span className="sr-only">First</span>
                <ChevronDoubleLeftIcon className="h-5 w-5" aria-hidden="true" />
              </button>
              <button
                onClick={() => previousPage()}
                disabled={!canPreviousPage}
                className="relative inline-flex items-center px-2 py-2 border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
              >
                <span className="sr-only">Previous</span>
                <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
              </button>
              {pageOptions.map((page: any, i: React.Key | null | undefined) => (
                <button
                  key={i}
                  onClick={() => gotoPage(i as number)}
                  className="relative inline-flex items-center px-4 py-2 border border-gray-300 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50"
                >
                  {Number(i) + 1}
                </button>
              ))}
              <button
                onClick={() => nextPage()}
                disabled={!canNextPage}
                className="relative inline-flex items-center px-2 py-2 border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
              >
                <span className="sr-only">Next</span>
                <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
              </button>
              <button
                onClick={() => gotoPage(pageCount - 1)}
                disabled={!canNextPage}
                className="relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
              >
                <span className="sr-only">Last</span>
                <ChevronDoubleRightIcon
                  className="h-5 w-5"
                  aria-hidden="true"
                />
              </button>
            </nav>
          </div>
        </div>
      </div>
    </>
  );
}

export default Table;
