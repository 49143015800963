import { equals, anyPass, isNil, isEmpty, defaultTo } from "ramda";

/*
 *   @param {any} value
 *   @returns {boolean}
 */

export const isNilOrEmpty = anyPass([isNil, isEmpty]);

/*
 *   @param {string} role
 *   @returns {string}
 */

export enum UserRole {
  Student = "student",
  Professor = "professor",
}

/*
 *   @param {string} role
 *   @returns {string}
 */

export const Gender = ["Male", "Female", "Other"];

/*
 *   @param {string} role
 *   @returns {boolean}
 */
export const isStudent = (role: string) => role === UserRole.Student;
export const isProfessor = (role: string) => role === UserRole.Professor;

/*
 *  @param {Date} date
 *  @returns {string} day of the week
 */

export const getDayOfWeek = (date: Date) => {
  const days = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  return days[date.getDay()];
};

/*
 *  Appointment
 *  Statuses
 */
export enum AppointmentStatus {
  Accepted = "accepted",
  Pending = "pending",
  Declined = "declined",
  Cancelled = "cancelled",
  Completed = "completed",
}
export const AppointmentTypes = [
  { title: "accepted", description: "Upcoming Appointments", current: true },
  { title: "pending", description: "Pending Appointments", current: false },
  { title: "declined", description: "Declined Appointments", current: false },
  { title: "cancelled", description: "Cancelled Appointments", current: false },
  { title: "completed", description: "Completed Appointments", current: false },
];
