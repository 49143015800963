import React from "react";
import { useForm, useFieldArray } from "react-hook-form";
import ButtonPrimary from "../../../../../Components/Button";
import { FormAvailability } from "../types";
import DatePicker from "../../../../../Components/Form/DatePicker";
import TimePicker from "../../../../../Components/Form/DatePicker/TimePicker";
import { getDayOfWeek } from "../../../../../Utils/helpers";
import { apiCreateAvailability } from "../../../../../Api/availability";
import { useAppDispatch } from "../../../../../Redux/hooks";
import { setSuccess } from "../../../../../Redux/slices/layout";
import { PlusCircleIcon, XCircleIcon } from "@heroicons/react/24/solid";
interface Props {
  onClose: () => void;
}
function NewAvailability({ onClose }: Props) {
  const dispatch = useAppDispatch();
  const {
    control,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<FormAvailability>({
    defaultValues: {
      date: new Date(),
      weekDay: "",
      newTimeSlots: [
        {
          startTime: new Date(),
          endTime: new Date(),
        },
      ],
    },
  });
  const { fields, append, remove } = useFieldArray({
    control,
    name: "newTimeSlots",
  });
  const watchDate = watch("date");
  const onSubmit = (data: FormAvailability) => {
    data.weekDay = getDayOfWeek(new Date(data.date));
    console.log(data);
    apiCreateAvailability(data)
      .then((res) => {
        console.log(res.data);
        dispatch(setSuccess("Successfully created availability"));
        onClose();
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <div className="p-8 mx-8 my-8">
      <div className="flex flex-col justify-start py-2">
        <h1 className="text-2xl font-semibold text-gray-600">
          New Availability
        </h1>
        <p className="text-sm text-gray-600 mt-2">
          Add a new availability for your students to book. Select a date and
          add time slots for that date. With each time slot you can add a start
          time and end time.
        </p>
      </div>
      <form className="mt-6" onSubmit={handleSubmit(onSubmit)}>
        <DatePicker
          label="Date"
          name="date"
          description="Select a date for your availability"
          control={control}
          rules={{
            required: "Date is required",
          }}
          errorMessage={errors.date?.message}
        />
        <hr className="w-full mt-2 mb-4" />
        <h1 className="text-sm font-semibold text-gray-600">Time Slots</h1>
        <p className="text-xs text-gray-600 mt-2">
          Add time slots for the date selected above. Remove time slots by
          clicking the remove button.
        </p>
        <div className="overflow-y-auto max-h-[20rem]">
          {fields.map((item, index) => (
            <>
              <div key={item.id} className="flex justify-between my-8 ">
                <TimePicker
                  control={control}
                  name={`newTimeSlots[${index}]`}
                  errorMessage="Please select a time slot"
                  rules={{ required: true }}
                  label="Time"
                  defaultDate={new Date(watchDate)}
                />
                <button
                  type="button"
                  onClick={() => remove(index)}
                  className="flex text-gray-500 hover:text-gray-900 font-semibold"
                >
                  <XCircleIcon className="h-8 w-8 mr-2 text-blue-900" />
                  Remove
                </button>
              </div>
              {fields.length > 1 && index !== fields.length - 1 && (
                <hr className="w-full mt-2 mb-4" />
              )}
            </>
          ))}
        </div>
        <hr className="w-full mt-2 mb-4" />
        <button
          type="button"
          className="mt-4 flex text-gray-500 hover:text-gray-900 font-semibold"
          onClick={() => append({ startTime: new Date(), endTime: new Date() })}
        >
          <PlusCircleIcon className="h-8 w-8 mr-2 text-blue-900" />
          Add Time Slot
        </button>

        <div className="flex justify-end items-center mt-6">
          <ButtonPrimary
            type="button"
            onPress={onClose}
            className="bg-white mr-4 text-black border hover:bg-gray-100 font-bold py-2 px-8 rounded-full"
          >
            Cancel
          </ButtonPrimary>
          <ButtonPrimary type="submit">Submit</ButtonPrimary>
        </div>
      </form>
    </div>
  );
}

export default NewAvailability;
