import axios from "axios";
import {path, defaultTo} from 'ramda' 
import { showLoading, hideLoading, setError } from "../Redux/slices/layout";
import {store} from "../Redux/store";
import { removePersitedUser } from "../Redux/store";
/*
* axios global config. Do not touch this file, yes this is my file
*/

export const AxiosGlobalSetup = () => {
  
    axios.defaults.baseURL = process.env.REACT_APP_BASE_URL;
    axios.defaults.withCredentials = true;
    
    axios.interceptors.request.use((config) => {
        if(config.method === 'post' && config.data instanceof FormData){
            config.headers['Content-Type'] = 'multipart/form-data';
        } else{
            config.headers['Content-Type'] = 'application/json';
        }
        return config; 
    });

    axios.interceptors.request.use((config) => {
        if(!config.headers['skipLoader']){ 
            store.dispatch(showLoading());
        }
        store.dispatch(setError(null));
        return config;
    })

    axios.interceptors.response.use((response) => {
        if(!defaultTo(false, response.config.headers['skipLoader'])){
            store.dispatch(hideLoading());
        }
        return response;
    },
    (error) => {
        if((error?.response?.status === 403) || (error?.response?.status === 401)){
            removePersitedUser();
            window.location.href = '/login';
        }
        const message = path(['response', 'data', 'message'], error);
        const errors = path(['response', 'data', 'errors'], error);
        const errorMessage = message || errors || error.message || 'An error occurred'; 
        window.scrollTo(0,0);
        store.dispatch(setError(errorMessage));  
        store.dispatch(hideLoading());
        return Promise.reject(error); 
    },
    );

};