import {createSlice} from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { removePersitedUser } from '../store'
import {apiLogout } from '../../Api/auth'  

export interface UserState {
    user: object | null,
}

const initialState: UserState = {
    user: null,
}
const userSlice = createSlice({
    name: "user",
   initialState,
    reducers: {
         setUser: (state, action: PayloadAction<object | null>) => {
                return {...state, user: action.payload} 
         },
         clearUser(state){
             state.user = null;
         }
    }
})
export const {setUser, clearUser} = userSlice.actions
export default userSlice;

//logout action
export const logout = () => async (dispatch: any) => { 
    apiLogout().then(() => {
        dispatch(clearUser());
        removePersitedUser();
    } ).catch((err) => {
        console.log(err);
    })  
};