import React from 'react'
import AuthedLayout from '../../Components/Layouts/AuthedLayout'
import { Routes, Route, Navigate } from "react-router-dom";
import { isProfessor } from '../../Utils/helpers';
import { useSelector } from 'react-redux';
import Appointments from './Professor/Appointments';
import Availability from './Professor/Availability';
import routes from '..'
import Students from './Professor/Students';
import Advisory from './Professor/Advisory';
import Profile from './Profile';
import Overview from './Overview';
import Courses from './Professor/Courses'; 

function Authed() {
  const user = useSelector((state: any) => state.user.user);
  const AuthedRoutes = (
    <Routes>
      {/* Common routes */}
      <Route path={routes.dashboard.home} element={<Overview />} /> 
      <Route path={routes.dashboard.profile} element={<Profile />} />
      <Route path={routes.dashboard.settings} element={<div>Settings</div>} /> 
      {isProfessor(user?.role) ? ( 
        <>
        <Route path="/dashboard/professor" element={<div>Professor only</div>} />
        <Route path={routes.dashboard.professor.courses} element={<Courses />} /> 
        <Route path={routes.dashboard.professor.students} element={<Students/>} />
        <Route path={routes.dashboard.professor.appointments} element={<Appointments />} /> 
        <Route path={routes.dashboard.professor.availability} element={<Availability />} /> 
        <Route path={routes.dashboard.professor.advisory} element={<Advisory/>} /> 
        </>
      ) : (
        <Route path={routes.dashboard.student.classes} element={<div className='text-2xl'>I think they completely forgot about me :(</div>} />
      )}
      {/* Fallback route */}
      <Route path="*" element={<Navigate to="/dashboard" replace />} />
    </Routes>
  );
  return (
    <AuthedLayout>
      <div className=' mt-4 lg:mt-10 mx-4 sm:mx-4 md:mx-10 lg:mx-40 max-w-7xl'>
      {AuthedRoutes}  
      </div>
    </AuthedLayout>
  )
}

export default Authed
