import React,{useState} from "react";
import { PencilSquareIcon } from "@heroicons/react/24/solid";
import Modal from "../../../../../Components/Modal";
import UpdateAppointment from "../Update";
import dayjs from "dayjs";
export const AppointmentColumns = [
  {
    Header: "Date",
    accessor: "endDateTime",
    Cell: ({ value }: any) => {
      return (
        <span className="text-gray-600 font-semibold">
          {dayjs(value).format("MMM  DD, YYYY hh:mm A")}
        </span>
      );
    },
  },
  {
    Header: "Status",
    accessor: "status",
  },
  {
    Header: "Student",
    accessor: "studentName",
  },
  {
    Header: "Guest",
    accessor: "guestName",
    Cell: ({ value }: any) => {
      return value ? value : "-";
    },
  },
  {
    Header: "Student Status",
    accessor: "studentCancelled",
    //if student cancelled show true else false
    Cell: ({ value }: any) => {
      return value ? (
        <div className="flex items-center">
          <div className="h-2.5 w-2.5 rounded-full bg-red-500 me-2"></div>{" "}
          Cancelled
        </div>
      ) : (
        <div className="flex items-center">
          <div className="h-2.5 w-2.5 rounded-full bg-green-500 me-2"></div>{" "}
          Active
        </div>
      );
    },
  },
  // {
  //   Header: "Reason",
  //   accessor: "reason",
  //   Cell: ({ value }: any) => {
  //     //string limit to 10 characters
  //     return value.length > 10 ? value.substring(0, 20) + "..." : value;
  //   }, 
  // }, 
  {
    Header: "Actions",
    accessor: "actions",
    Cell: ({ row }: any) => {

      const [showModal, setShowModal] = useState(false);
      return (
      <>
      <div className="flex justify-start items-center">
        <button className="text-gray-500 hover:text-gray-900" onClick={()=> setShowModal(true)} >
          <PencilSquareIcon className="h-5 w-5 mr-2" /> 
          Edits
        </button>
      </div>
      {showModal && (
        <Modal
          show={showModal}
          onClose={()=> setShowModal(false)}
          maxWidth="2xl"  
        >
          <UpdateAppointment 
          appointments={row.original}
          closeModal={setShowModal} 
           />
        </Modal>
      )}
      </>
      )
    },
  },
];
