import React from "react";
import { useSelector } from "react-redux";
import ViewProfile from "./View";
import ChangePassword from "./ChangePassword";

function Profile() {
  const user = useSelector((state: any) => state.user.user);

  return (
    <div className="">
      <div className="flex justify-between items-center">
        <h1 className="text-2xl font-semibold text-gray-600">Profile</h1>
      </div>
      <div className="flex flex-col w-full lg:w-2/4 sm:w-full md:w-2/2 justify-between mt-8">    
          <div className="p-4 sm:p-8 bg-white shadow mb-8 rounded-lg">
            <ViewProfile user={user} />
          </div>

          <div className="p-4 sm:p-5 bg-white shadow rounded-lg"> 
            <ChangePassword />
          </div>
        </div>
    </div>
  );
}

export default Profile;
