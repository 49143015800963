import React from 'react'
import TextInput from '../../../../../Components/Form/TextInput'
import ButtonPrimary from '../../../../../Components/Button'
import { useForm } from 'react-hook-form'
import {apiForgotPassword} from '../../../../../Api/auth';
import { Link } from 'react-router-dom';
type Inputs = {
    email: string;
  };
interface props {
    setStep: React.Dispatch<React.SetStateAction<number>>
    userId: React.Dispatch<React.SetStateAction<string>>
}
function SendEmail({setStep, userId}: props) {
    const {
        handleSubmit,
        control,
        formState: { errors },
      } = useForm<Inputs>(
        {
          defaultValues: {
            email: "",
          },
        } 
      );
    
      const onSubmit = (data: any) => {
        apiForgotPassword(data).then((res) => {
            setStep(2);
            userId(res.data.userId);
        }).catch((err) => {
            console.log(err);
        });
      };
  return (
    <div>
     <form onSubmit={handleSubmit(onSubmit)}>
         <TextInput
            label="Enter your email"
            description='We will send you a verification code to your email' 
            name="email"
            control={control}
            rules={{
              required: "Email is required", 
                pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    message: "Invalid email address", 
                },
            }}
            errorMessage={errors.email && errors.email.message}
          />
        <div className="mt-8">
          <ButtonPrimary type="submit">Send</ButtonPrimary>
        </div>
        </form>
        {/* go back to login */}
        <div className="mt-4">
          <span className="font-medium text-sm text-gray-900">
            Remembered your password?{" "}
          </span>
          <Link
            to={"/"}
            className="text-sm font-medium text-indigo-600 hover:text-indigo-500"
          >
            Login
          </Link>
        </div> 
    </div>
  )
}

export default SendEmail
