import React from 'react'
import './style.m.scss' 
type Props = {
    children: React.ReactNode
    }

function GuestLayout({ children }: Props) {
  return (
    <div className="relative flex min-h-screen flex-col justify-center overflow-hidden bg-gray-50 py-6 sm:py-12">     
     {children}
    </div>
  )
}

export default GuestLayout
