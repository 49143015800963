import React from 'react'
import { Routes, Route } from "react-router-dom";
import Registration from './Registartion';
import Verify from  './Verify'; 
import AddInfo from './AddInfo';


function Register() {

  // steps: 1. register, 2. confirm email, 3. add profile information, 4. verified.
  //first name , eamil , password, confirm password, username,
  return (
    <div>
      <Routes>
        <Route path="/" element={<Registration />} />
        <Route path="verify" element={<Verify />} /> 
        <Route path="addinfo" element={<AddInfo />} />
      </Routes>
    </div>
  )
}

export default Register
