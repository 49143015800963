import React, { useState, useEffect } from "react";
import { apiGetAgregation } from "../../../../Api/overview";

function OverviewProfessor() {
  const [agregation, setAgregation] = useState<any>(null);
  const apiCalls = () => {
    apiGetAgregation()
      .then((res) => {
        setAgregation(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    apiCalls();
  }, []);
  return (
    <div>
      <div className="flex justify-between items-center mb-4">
        <h1 className="text-2xl font-semibold text-gray-600">
          Good Morning, Emily
        </h1>
      </div>
      <div className="flex space-x-6 my-10 pb-10">
        <div className="bg-white w-full rounded-lg shadow p-8">
          <div className="h-[400px]">
            <h1 className="font-bold capitalize">
              Today's Appointment
            </h1>
          </div>
        </div>
        <div className="w-1/2 space-y-4">
          <div className="bg-white h-1/2 shadow rounded-lg p-4">
            <h1 className="font-bold capitalize">
              Upcoming Appointments
            </h1>
            <p className="text-sm text-gray-400"> Next Appointments</p>
          </div>
          <div className="bg-white h-1/2 shadow rounded-lg p-4">
            <h1 className="font-bold capitalize">
              Pending Appointments
            </h1>
            <p className="text-sm text-gray-400"> Appointments to be approved</p> 
          </div>
        </div>
      </div>
      {/* classes cards */}
      <div className="flex space-x-3 mt-10">
        {/* your courses */}
        <div className="bg-white w-2/3 shadow rounded-lg p-4">
          <h1 className="font-bold capitalize">Your Courses</h1>
          <p className="text-sm text-gray-400"> Current courses you're teaching</p>
        </div>
        {/* no of students */}
        <div className="bg-white w-1/3 shadow rounded-lg p-4">
          <h1 className="font-bold capitalize"># Students</h1>
          <p className="text-sm text-gray-400"> Total number of students</p>
          {agregation && (
            <div className="flex space-x-4">
              <div className="bg-white w-1/2 rounded-lg p-4">
                <h1 className="font-bold capitalize">
                  {agregation?.aggregates?.studentsCount}  
                </h1>
                </div>
              </div>
          )}   
        </div>
        {/* students you're adivising */}
        <div className="bg-white w-1/3 shadow rounded-lg p-4">
          <h1 className="font-bold capitalize"># Advising</h1>
          <p className="text-sm text-gray-400"> Total Students you're advising</p> 
        </div>
      </div>
    </div>
  );
}

export default OverviewProfessor;
