import React,{useState} from "react";
import ButtonPrimary from "../../../../Components/Button";
import { Link } from "react-router-dom";
import TextInput from "../../../../Components/Form/TextInput";
import { useForm } from "react-hook-form";
import SelectBox from "../../../../Components/Form/Select";
import { UserRole, Gender } from "../../../../Utils/helpers";
import DatePicker from "../../../../Components/Form/DatePicker";
import {useLocation } from "react-router-dom";
import {apiAddInfo} from '../../../../Api/auth';
import Modal from "../../../../Components/Modal";
interface Iinputs {
  userId: string;
  role: string;
  major: string;
  minor: string;
  department: string;
  grade: string;
  gender: string;
  title: string;
  birthdate: string;
}

function AddInfo() {
  const location = useLocation();
  const user = location.state?.user;
  const [success, setSuccess] = useState<boolean>(false);  
  console.log(user);
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<Iinputs>({
    defaultValues: {
      birthdate: "", 
      userId: user?._id,
      role: user?.role,
    },
  });
  const onSubmit = (data: Iinputs) => {
    apiAddInfo(data).then((res) => {
      setSuccess(true);
    }).catch((err) => {
      console.log(err);
    });
  };
  return (
    <div className="container mx-auto">
      <div className="flex justify-center px-6 my-12">
        <div className="w-full xl:w-3/4 lg:w-11/12 flex">
          {/* Col */}
          <div
            className="w-full h-auto bg-gray-400 hidden lg:block lg:w-1/2 bg-cover rounded-l-lg"
            style={{
              backgroundImage:
                'url("https://source.unsplash.com/K4mSJ7kc0As/600x800")',
            }}
          />
          {/* Col */}
          <div className="w-full lg:w-1/2 bg-white p-5 shadow sm:rounded-lg">
            <h2 className="mt-6 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
              Complete your profile
            </h2>
            {/* form */}
            {user && (
              <div className="text-center pt-4">
                <p className="text-sm text-gray-600">Welcome, {user?.username}!</p>
              </div>
            )}
            <form
              className="px-8 pt-6 pb-8 mb-4 bg-white rounded"
              onSubmit={handleSubmit(onSubmit)}
            >
              {user?.role === UserRole.Student && (
                <>
                  <TextInput
                    label="Major"
                    name="major"
                    control={control}
                    rules={{
                      required: "Please enter your major",
                    }}
                    errorMessage={errors.major && errors.major.message}
                  />
                  <TextInput
                    label="Minor"
                    name="minor"
                    control={control}
                    rules={{
                      required: "Please enter your minor",
                    }}
                    errorMessage={errors.minor && errors.minor.message}
                  />
                  <TextInput
                    label="Grade"
                    name="grade"
                    control={control}
                    rules={{
                      required: "Please enter your grade",
                    }}
                    errorMessage={errors.grade && errors.grade.message}
                  />
                </>
              )}
              {user?.role === UserRole.Professor && (
                <>
                <TextInput
                    label="Title"
                    name="title"
                    control={control}
                    rules={{
                      required: "Please enter your title",
                    }}
                    errorMessage={errors.title && errors.title.message}
                  />
                  <TextInput
                    label="Department"
                    name="department"
                    control={control}
                    rules={{
                      required: "Please enter your department",
                    }}
                    errorMessage={
                      errors.department && errors.department.message
                    }
                  />
                  
                </>
              )}

              <SelectBox
                label="Gender"
                name="gender"
                control={control}
                rules={{
                  required: "Please select an option",
                }}
                errorMessage={errors.gender && errors.gender.message} 
                options={
                  <>
                    <option value="">Select an option</option>
                    {Gender.map((item, index) => (
                      <option key={index} value={item}>{item}</option>
                    ))}
                  </>
                }
              />

              <DatePicker
                label="Birthdate"
                name="birthdate"
                control={control}
                rules={{
                  required: "Please enter your birthdate",
                }}
                errorMessage={errors.birthdate && errors.birthdate.message}
              />

              <div className="text-center pt-4">
                <ButtonPrimary type="submit">Finish</ButtonPrimary>
              </div>
            </form>
          </div>
        </div>
      </div>
      {success && (
        <Modal show={success} onClose={() => setSuccess(false)}> 
          <div className="text-center py-4">  
          <h2 className="mt-6 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
              Congratulations! 
            </h2>
            <p className=" text-gray-900"> 
              Your account has been created successfully! <br/>
               Time to login and start using the app! 
            </p>
            <div className="m-4">
              <ButtonPrimary>
              <Link
                className="font-semibold leading-6 text-whit"
                to={"/login"}
              >
                Login
              </Link>
              </ButtonPrimary>
            </div>
          </div>
        </Modal>
      )}
    </div>
  );
}

export default AddInfo;
