import { useState, useEffect, useMemo } from "react";
import { useForm } from "react-hook-form";
import ButtonPrimary from "../../../../Components/Button";
import Table from "../../../../Components/Table";
import { CourseColumns } from "./Columns";
import { ArchiveBoxXMarkIcon } from "@heroicons/react/24/outline";
import TextInput from "../../../../Components/Form/TextInput";
import TextAreaInput from "../../../../Components/Form/TextArea";
import Modal from "../../../../Components/Modal";
import { useDispatch } from "react-redux";
import { setSuccess } from "../../../../Redux/slices/layout";
import { apiAddCourse, apiGetAllCourses } from "../../../../Api/courses";
import { CourseForm } from "./types";
import { useAppSelector } from "../../../../Redux/hooks";

function Courses() {
  const success = useAppSelector((state) => state.layout.success);
  const [courses, setCourses] = useState([]);
  const columns = useMemo(() => CourseColumns, []);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const { control, handleSubmit, reset } = useForm<CourseForm>({
    defaultValues: {
      courseCode: "",
      courseName: "",
      courseDepartment: "",
      courseDescription: "",
    },
  });
  const dispatch = useDispatch();

  const getCourses = () => {
    apiGetAllCourses()
      .then((res) => {
        setCourses(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getCourses();
  }, [success]);

  const handleAddCourseModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false); // Close the modal
  };

  const onAddCourseSubmit = async (data: CourseForm) => {
    apiAddCourse(data).then((res) => {
      dispatch(setSuccess("Course successfully created"));
      setIsModalOpen(false);
      reset();
    });
  };

  return (
    <div>
      <div className="flex justify-between items-center">
        <h1 className="text-2xl font-semibold text-gray-600">Courses</h1>
        <ButtonPrimary onPress={handleAddCourseModal}>Add Course</ButtonPrimary>
      </div>
      <div className="mt-6">
        {Array.isArray(courses) && courses.length > 0 ? (
          <Table columns={columns} data={courses} />
        ) : (
          <div className="flex flex-col justify-center items-center mt-40">
            <ArchiveBoxXMarkIcon className="h-10 w-10 text-gray-400" />
            <h1 className="font-semibold text-gray-600">No courses found</h1>
          </div>
        )}
      </div>
      <Modal show={isModalOpen}>
        <div className="p-8 mx-8 my-8">
        <div className="flex flex-col justify-start py-2">
        <h1 className="text-2xl font-semibold text-gray-600">
          New Course 
        </h1>
        <p className="text-sm text-gray-600 mt-2">
          Add a new course for your students. Enter the course code, name, department and description.
          Adding students to the course makes it possible for a student to book an appointment with a professor. 
          
        </p>
      </div>
          <div>
            <form onSubmit={handleSubmit(onAddCourseSubmit)}>
              <div>
                <TextInput
                  label="Course Code"
                  description="Enter the course code eg. INF120 "
                  name="courseCode"
                  control={control}
                  rules={{ required: true }}
                  errorMessage="This is required"
                />
                <TextInput
                  label="Course Name"
                  description="Enter the name of the course eg. Intro to Programming I"
                  name="courseName"
                  control={control}
                />
                <TextInput
                  label="Course Department"
                  description="Enter the course's department eg. Science"
                  name="courseDepartment"
                  control={control}
                />
                <TextAreaInput
                  label="Course Description"
                  description="Enter description of course"
                  name="courseDescription"
                  control={control}
                />
              </div>
              <div className="flex justify-end items-center mt-6">
                <ButtonPrimary onPress={handleCloseModal}
                  className="bg-white mr-4 text-black border hover:bg-gray-100 font-bold py-2 px-8 rounded-full"

                >Cancel</ButtonPrimary>
                <ButtonPrimary type="submit">Add Course</ButtonPrimary>
              </div>
            </form>
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default Courses;
