import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import Login from "../Guest/Login";
import Register from "../Guest/Register";
import GuestLayout from "../../Components/Layouts/GuestLayout";
import appRoutes from "../../Routes"
import ResetPassword from "../Guest/Reset";
function GuestRouter() {
  const GuestRoutes = () => {
    return (
      <>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/register/*" element={<Register />} />
          <Route path={appRoutes.forgotpassword} element={<ResetPassword />} /> 
          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
      </>
    );
  };

  return (
    <>
      <GuestLayout>
        {GuestRoutes()}
      </GuestLayout>
    </>
  );
}

export default GuestRouter;
