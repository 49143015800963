import React from "react";
import { NavLink } from "react-router-dom";
import { isProfessor } from "../../Utils/helpers";
import {
  HomeIcon,
  UserCircleIcon,
  AcademicCapIcon,
  UsersIcon,
  RectangleStackIcon,
  CalendarDaysIcon,
  Cog8ToothIcon,
  BriefcaseIcon,
} from "@heroicons/react/24/outline";
import routes from "../../Routes";
import "./styles.m.scss";

interface Props {
  role: string;
}
function AuthHeader({ role }: Props) {
  const links = () => {
    return (
      <>
        <nav className="flex flex-1 flex-col">
          <NavLink
            to={routes.dashboard.home}
            className={({ isActive }) =>
              isActive ? "active_link" : "normal_link"
            }
            end
          >
            <HomeIcon className="h-6 w-6" />
            Overview
          </NavLink>
          <NavLink
            to={routes.dashboard.profile} 
            className={({ isActive }) =>
              isActive ? "active_link" : "normal_link"
            }
          >
            <UserCircleIcon className="h-6 w-6" />
            Profile
          </NavLink>
        </nav>
        {isProfessor(role) ? (
          <nav className="flex flex-1 flex-col">
            <NavLink
              to={routes.dashboard.professor.appointments}
              className={({ isActive }) =>
                isActive ? "active_link" : "normal_link"
              }
            >
              <BriefcaseIcon className="h-6 w-6" />
              Appointments
            </NavLink>
            {/* availability */}
            <NavLink
              to={routes.dashboard.professor.availability}
              className={({ isActive }) =>
                isActive ? "active_link" : "normal_link"
              }
            >
              <CalendarDaysIcon className="h-6 w-6" />
              Availability
            </NavLink>

            <NavLink
              to={routes.dashboard.professor.courses}
              className={({ isActive }) =>
                isActive ? "active_link" : "normal_link"
              }
            >
              <RectangleStackIcon className="h-6 w-6" />
              Courses
            </NavLink>
            <NavLink
              to={routes.dashboard.professor.advisory}
              className={({ isActive }) =>
                isActive ? "active_link" : "normal_link"
              }
            >
              <AcademicCapIcon className="h-6 w-6" />
              Advisory
            </NavLink>
            <NavLink
              to={routes.dashboard.professor.students}
              className={({ isActive }) =>
                isActive ? "active_link" : "normal_link"
              }
            >
              <UsersIcon className="h-6 w-6" />
              Students
            </NavLink>
          </nav>
        ) : (
          <nav className="flex flex-1 flex-col">
            <NavLink
              to={routes.dashboard.student.classes} 
              className={({ isActive }) =>
                isActive ? "active_link" : "normal_link"
              }
              end
            >
              <RectangleStackIcon className="h-6 w-6" />
              Classes
            </NavLink>
          </nav>
        )}
        <nav className="flex flex-1 flex-col">
          <NavLink
            to={routes.dashboard.settings}
            className={({ isActive }) =>
              isActive ? "active_link" : "normal_link"
            }
            end
          >
            <Cog8ToothIcon className="h-6 w-6" />
            Settings
          </NavLink>
        </nav>
      </>
    );
  };
  return <div className="text-white">{links()}</div>; 
}

export default AuthHeader;
