import dayjs from "dayjs";
import React from "react";
interface Props {
  student: any;
}
function ViewStudent({ student }: Props) {
  return (
    <>
      {student && (
        <div className="bg-white max-w-2xl shadow overflow-hidden sm:rounded-lg">
          <div className="px-4 py-5 sm:px-6 mx-8 my-8">
            <div className="w-20 h-20 mb-2 md:mr-6 ">
              <img
                className="object-cover rounded-full"
                src="https://tailwindflex.com/public/images/user.png"
              />
            </div>
            <h3 className="text-2xl font-medium text-gray-900">
              {student.firstName} {student.lastName}
            </h3>
            <p className="text-md capitalize">{student.major}</p>

            <p className="mt-1 text-sm text-gray-500 capitalize"> 
              {student.minor}
            </p>
          </div>
          <div className="border-t border-gray-200">
            <dl className="p-8 mx-4 mb-8">
              <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">
                  Date of Birth
                </dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                  {dayjs(student.birthdate).format("MM-DD-YYYY")}
                </dd>
              </div>
              <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">Gender</dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                  {student.gender}
                </dd>
              </div>
              <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">Grade</dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                  {student.grade}
                </dd>
              </div>
              <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">Email</dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                  {student.email}
                </dd>
              </div>
              <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">Created</dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                {dayjs(student.createdAt).format("MM-DD-YYYY")}
                </dd>
              </div>
            </dl>
          </div>
        </div>
      )}
    </>
  );
}

export default ViewStudent;
