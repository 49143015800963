import React,{useState} from 'react'
import AddEmail from './Steps/Email'
import Verification from './Steps/Verification'
import ChangePassword from './Steps/Password';



function ResetPassword() {
    const [step, setStep] = useState<number>(1);
    const [userId, setUserId] = useState<string>("");
  
//switch case 
const resetSteps = () => {
    switch (step) { 
        case 1:
        return <AddEmail setStep={setStep} userId={setUserId} />;
        case 2:
        return <Verification setStep={setStep} userId={userId} />;
        case 3:
        return <ChangePassword />;
        default:
        return null; 
    }
};

  return (
    <div className="bg-gray-50">
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <h2 className="mt-6 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
            Reset your password
        </h2>
      </div>
      <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-[480px]">
        <div className="bg-white px-8 py-20 shadow sm:rounded-lg sm:px-12 text-center">
         {resetSteps()}  
        </div>
      </div>
    </div>
  )
}

export default ResetPassword
