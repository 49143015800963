import {createSlice} from '@reduxjs/toolkit'
import type {PayloadAction} from '@reduxjs/toolkit'

export interface LayoutState {
    showLoader: boolean,
    error: null | string,
    success: null | string

}
const initialState: LayoutState = {
    showLoader: false,
    error: null,
    success: null
}

const layoutSlice = createSlice({
    name: "layout",
    initialState,
    reducers: {
        showLoading(state) {
            state.showLoader = true;
        },
        hideLoading(state){
            state.showLoader = false;
        },
        setError(state, action: PayloadAction<null | string>){
            state.error = action.payload;
        },
        clearError(state){
            state.error = null
        },
        setSuccess(state, action:PayloadAction <string>){
            state.success = action.payload; 
        },
        clearSuccess(state){
            state.success = null;
        }

    }
});

export const {showLoading, hideLoading, setError, clearError, setSuccess, clearSuccess} = layoutSlice.actions
export default layoutSlice;
