import React from 'react'
import './styles.m.scss'
import { useSelector, useDispatch } from 'react-redux'
import Toast from '../Toast'
import { setError } from '../../Redux/slices/layout'
import { forEachObjIndexed } from 'ramda';
import { isNilOrEmpty } from '../../Utils/helpers';

function ErrorNotification() { 
   const dispatch = useDispatch()
   const errors = useSelector((state: any) => state.layout.error) 
    let toasts: string[] = [];
    if(errors && typeof errors === 'object'){
        forEachObjIndexed((value, key) => {
          toasts.push(value);
        }, errors);
    } else {
        toasts = [errors];
    } 
  return (
    <div className="toast">
    {toasts.map((toast, index) => {
            return (
              <Toast
                key={index}
                show={!isNilOrEmpty(toast)}   
                message={toast}
                onClose={() => dispatch(setError(''))} 
                type="error" 
              />
        );
     }
     )}
    </div>
  );
}

export default ErrorNotification
