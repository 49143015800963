import { useState } from "react";
import { TrashIcon, EyeIcon } from "@heroicons/react/24/solid"; 
import Modal from "../../../../../Components/Modal";
import Confirm from "../../../../../Components/Confirm"; 
import { apiRemoveStudentAdvisor } from "../../../../../Api/students";
import ViewStudent from "../../Students/View";

export const StudentColumns = ({ onStudentRemoved = () => {} }) => [
  {
    Header: "First Name",
    accessor: "firstName",
  },
  {
    Header: "Last Name",
    accessor: "lastName",
  },
  {
    Header: "Grade",
    accessor: "grade",
  },
  {
    Header: "Major(s)",
    accessor: "major",
  },
  {
    Header: "Actions",
    accessor: "actions",
    Cell: ({ row }: any) => {
      const [showModal, setShowModal] = useState(false);
      const [confirm, setConfirm] = useState(false);

      return (
        <>
          <div className="flex justify-start items-center">
            <button
              className="text-gray-500 hover:text-gray-900"
              onClick={() => setShowModal(true)}
            >
              <EyeIcon className="h-5 w-5 mr-2"  />  
              View
            </button>
            <button
              className="text-gray-500 hover:text-gray-900 ml-2"
              onClick={() => setConfirm(true)}
            >
              <TrashIcon className="h-5 w-5 mr-2"/> 
              Remove 
            </button>
          </div>
          {showModal && (
            <Modal show={showModal} onClose={() => setShowModal(false)}>
              <ViewStudent student={row.original} />
            </Modal>
          )}
          {confirm && (
            <Confirm
              title="Delete Student"
              onClose={() => setConfirm(false)}
              onConfirm={async () => {
                let del = await apiRemoveStudentAdvisor(row.original._id);
                onStudentRemoved();
              }}
            >
              Are you sure you want to remove this student?
            </Confirm>
          )}
        </>
      );
    },
  },
];
