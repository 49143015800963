import React,{useState} from "react"; 
import dayjs from "dayjs";
import { PencilSquareIcon, TrashIcon } from "@heroicons/react/24/solid";
import Modal from "../../../../../Components/Modal"; 
import Confirm from "../../../../../Components/Confirm";
import UpdateAvailability from "../Update";
import { apiDeleteAvailability } from "../../../../../Api/availability"; 
import { useAppDispatch } from "../../../../../Redux/hooks";
import { setSuccess } from "../../../../../Redux/slices/layout";
export const AvailabilityColumns  = [
    {
        Header: "Date",
        accessor: "date", 
        Cell: ({ value }: any) => {
          return  <span className="text-gray-600 font-semibold">{dayjs(value).format("MM/DD/YYYY")}</span> 
          }, 
      },
      {
        Header: "Day",
        accessor: "weekDay",
      },
      {
        Header: "# of Slots", 
        accessor: "timeSlots",
        Cell : ({value}: any) => {
            return value.length
        }
      },
      {
        Header: "Created At",
        accessor: "createdAt",
        Cell: ({ value }: any) => {
          return dayjs(value).format("MM/DD/YYYY");
        }, 
      },
      {
        Header: "Actions",
        accessor: "actions",
        Cell: ({ row }: any) => {
          const dispatch = useAppDispatch();
          const [showModal, setShowModal] = useState(false);
          const [confirm, setConfirm] = useState(false);
          const handelDelete = () => {
            apiDeleteAvailability(row.original._id).then((res) => {
              if(res.data.success === true) {
                dispatch(setSuccess(res.data.message));  
              }
              setConfirm(false);
            }
            ).catch((err) => {
              console.log(err);
            }) 

          }
          return (
            <>
            <div className="flex justify-start items-center">
              <button className="text-gray-500 hover:text-gray-900" onClick={()=> setShowModal(true)} >
                <PencilSquareIcon className="h-5 w-5 mr-2" /> 
                Edit
              </button>
              <button className="text-gray-500 hover:text-gray-900 ml-2" onClick={()=> setConfirm(true)}>
                <TrashIcon className="h-5 w-5 mr-2" />
                Delete
              </button>
            </div>
            {showModal && (
              <Modal
                show={showModal}
                onClose={()=> setShowModal(false)}
                maxWidth="2xl"  
              >
                <UpdateAvailability 
                data={row.original}
                closeModal={setShowModal} 
                 />
              </Modal>
            )}
            {confirm && (
              <Confirm
                title="Delete Availability"
                onClose={() => setConfirm(false)}
                onConfirm={() => handelDelete()}
              >
                Are you sure you want to delete this availability?
              </Confirm>
            )}
            </>
          );
        }
      },
];