//user endpoints
import axios from "axios";
export const apiTest = () => axios.get('/user/ping');
export const login = (data: any) => axios.post('/user/login', data);
export const apiLogout = () => axios.post('/user/logout');  
export const apiRegister = (data: any) => axios.post('/user/register', data);
export const apiResendCode = (data: any) => axios.post('/user/resendcode', data);
export const apiVerifyCode = (data: any) => axios.post('/user/comparecode', data);
export const apiAddInfo = (data: any) => axios.put('/user/info', data); 

//password reset endpoints
export const apiForgotPassword = (data: any) => axios.post('/user/sendcode', data); 
export const apiResetPassword = (data: any) => axios.post('/user/reset-password', data);
export const apiChangePassword = (data: any) => axios.post('/user/changepassword', data); 