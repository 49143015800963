import { useEffect, useState } from "react";
import ButtonPrimary from "../../../../../Components/Button";
import { useAppDispatch } from "../../../../../Redux/hooks";
import { TrashIcon, UserCircleIcon } from "@heroicons/react/24/solid";
import { setSuccess } from "../../../../../Redux/slices/layout";
import { UserGroupIcon } from "@heroicons/react/24/outline";
import { CourseProps, Student } from "../types";
import { apiAddStudent, apiRemoveStudent } from "../../../../../Api/courses";
import { apiGetAllStudents } from "../../../../../Api/students";
import SearchableSelectBox from "../../../../../Components/SearchSelect";

function UpdateCourse({ data, showModal }: CourseProps) {
  const dispatch = useAppDispatch();
  const [selected, selectedOption] = useState<string>("");
  const [students, setStudents] = useState([]);

  const getStudents = () => {
    apiGetAllStudents().then((res) => {
      const mappedAllStudents = res.data.students.map((student: any) => ({
        label: `${student.firstName} ${student.lastName}`,
        value: student._id.toString(),
      }));
      setStudents(mappedAllStudents);
    });
  };
 
  const handleRemoveStudent = (student: Student) => { 
    apiRemoveStudent(student._id, data._id)
      .then((res) => {
        dispatch(setSuccess("Successfully removed student"));
        showModal(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const onAddStudent = () => {
    if (selected) {
      apiAddStudent(selected, data._id)
        .then((res) => {
          dispatch(setSuccess("Successfully added student"));
          selectedOption("");
          showModal(false);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  useEffect(() => {
    getStudents(); 
  }, []); 

  return (
    <div className="overflow-auto mx-14 my-14">
      <div className="flex flex-col justify-start py-2">
        <h1 className="text-2xl font-semibold text-gray-600 capitalize">Update {data?.courseName}</h1> 
        <p className="text-sm my-1 text-gray-600">
          Add students to Enroll them to your course. Students listed below are
          currently enrolled in your course. They can be removed by clicking the
          trash icon.
        </p>
      </div>
      <>
        {data.students.length === 0 ? (
          <>
            <div className="flex flex-col justify-between  mt-4">
              <h1 className="text-md font-semibold text-gray-600">
                Students Enrolled
              </h1>
              <span className="capitalize italic text-xs">
                {data.courseName}
              </span>
            </div>
            <hr className="w-full mt-2" />
            <div className="flex flex-col justify-center items-center mt-10">
              <UserGroupIcon className="h-8 w-8 mb-2 text-gray-400" />
              <h1 className="text-sm font-semibold text-gray-600">
                No students in{" "}
                <span className="capitalize">{data.courseName}</span>
              </h1>
            </div>
          </>
        ) : (
          <div className="my-4 overflow-y-auto max-h-[20rem]">
            <h1 className="text-sm font-semibold text-gray-600">Students</h1>
            {/* description */}
            <p className="text-xs text-gray-600 mt-2">
              Click the trash icon to remove a student from your course.
            </p>
            <hr className="w-full mt-2" />
            {data.students.map((student, index: number) => (
              <div className="w-1/2 my-4" key={index}>
                <div className="flex justify-between items-center mt-4">
                  <div className="flex justify-start items-center">
                    <span className="text-sm flex text-gray-600">
                      <UserCircleIcon className="h-5 w-5 mr-2 text-gray-400" />
                      {student.firstName} {student.lastName}
                      {student.grade && (
                        <span className="bg-yellow-500 text-yellow-800  text-xs font-medium ml-2 px-2.5 py-0.5 rounded-lg">
                          {student.grade}
                        </span>
                      )}
                    </span>
                  </div>
                  <div className="flex justify-end">
                    <button
                      className="text-gray-500 hover:text-gray-900"
                      onClick={() => {
                        handleRemoveStudent(student);
                      }}
                    >
                      <TrashIcon className="h-6 w-6 ml-2 text-blue-900" />
                    </button>
                  </div>
                </div>
                <hr className="w-full mt-2" />
              </div>
            ))}
          </div>
        )}
      </>
      <div className="pb-6">
        <h1 className="text-sm font-semibold text-gray-600">Enroll Student</h1>
        <p className="text-xs text-gray-600 mt-2">
          Search for a student to enroll them to your course.
        </p>
        <hr className="w-full mt-2" />
        <SearchableSelectBox
          onSelect={selectedOption}
          label="Select a Student"
          options={students}
        />
      </div>
      <div className="flex justify-end mt-10">
        <ButtonPrimary
          onPress={() => showModal(false)}
          className="bg-white mr-4 text-black border hover:bg-gray-100 font-bold py-2 px-8 rounded-full"
        >
          Cancel
        </ButtonPrimary>
        <ButtonPrimary onPress={onAddStudent}>Add Student</ButtonPrimary>
      </div>
    </div>
  );
}

export default UpdateCourse;
