import React, { useState } from "react";
import { Select, Label, Text } from "react-aria-components";
import './styles.m.scss' 
type SearchableSelectBoxProps = {
  label: string;
  options: { label: string; value: string }[];
  onSelect: (selectedValue: string) => void;
};

function SearchableSelectBox({
  label,
  options,
  onSelect,
}: SearchableSelectBoxProps) {
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedOption, setSelectedOption] = useState("");
  const [isDropdownOpen, setDropdownOpen] = useState(false);

  const filteredOptions = options.filter((option) =>
    option.label.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const toggleDropdown = () => {
    setDropdownOpen(!isDropdownOpen);
  };

  const handleOptionClick = (option: { label: string; value: string }) => {
    setDropdownOpen(false);
    setSelectedOption(option.label);
    onSelect(option.value); // Pass the selected value to the callback
  };

  return (
    <div className="relative mt-2 mb-2">
      <div className="relative w-full">
        <button
          className="px-2 w-full text-left border-1.5 bg-gray-50 rounded-md shadow-sm border-gray-200 py-2 text-gray-900 sm:text-sm sm:leading-6 focus:outline-none focus:border-indigo-600 focus:ring-indigo-600"
          onClick={toggleDropdown}
        >
          {selectedOption || "Select a student"}
        </button>

        {/* Dropdown implementation */}
        {isDropdownOpen && (
          <div className="absolute z-50 mt-2 w-full px-2 py-1 bg-white border rounded-md shadow-lg my-dropdown">
            <input
              type="text"
              className="px-2 w-full border-1.5 bg-gray-50 rounded-md shadow-sm border-gray-200 py-2 text-gray-900 sm:text-sm sm:leading-6 focus:outline-none focus:border-indigo-600 focus:ring-indigo-600"
              placeholder="Search..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
            <ul>
              {filteredOptions?.slice(0, 4).map((option) => (
                <li
                  key={option.value}
                  onClick={() => handleOptionClick(option)}
                  className="cursor-pointer hover:bg-gray-100 py-1 px-2"
                >
                  {option.label}
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
    </div>
  );
}

export default SearchableSelectBox;
