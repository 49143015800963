import React from "react";
import ButtonPrimary from "../../../../Components/Button";
import TextInput from "../../../../Components/Form/TextInput";
import SelectBox from "../../../../Components/Form/Select";
import { useForm } from "react-hook-form";
import { UserRole } from "../../../../Utils/helpers";
import { Link } from "react-router-dom";
import {apiRegister} from '../../../../Api/auth'; 
import { useNavigate } from "react-router-dom";
import Routes from "../../../../Routes";
type Inputs = {
  username: string;
  firstName: string;
  lastName: string;
  role: string;
  email: string;
  password: string;
  confirmPassword: string;
};
function Registration() {
  const navigate = useNavigate();
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<Inputs>({});
  const onSubmit = (data: Inputs) => {
    apiRegister(data).then((res) => { 
      navigate(Routes.register.verify, {state: {user: res.data.result, message: res.data.message}});  
    }).catch((err) => {
      console.log(err);
    });
  };
  return (
    <div className="container mx-auto">
      <div className="flex justify-center px-6 my-12">
        {/* Row */}
        <div className="w-full xl:w-3/4 lg:w-11/12 flex">
          {/* Col */}
          <div
            className="w-full h-auto bg-gray-400 hidden lg:block lg:w-1/2 bg-cover rounded-l-lg"
            style={{
              backgroundImage:
                'url("https://source.unsplash.com/K4mSJ7kc0As/600x800")',
            }}
          />
          {/* Col */}
          <div className="w-full lg:w-1/2 bg-white p-5 shadow sm:rounded-lg">
            <h2 className="mt-6 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
              Create an account
            </h2>
            {/* form */}
            <form
              className="px-8 pt-6 pb-8 mb-4 bg-white rounded"
              onSubmit={handleSubmit(onSubmit)}
            >
              <div className="mb-4">
                <TextInput
                  label="Username"
                  name="username"
                  control={control}
                  rules={{
                    required: "Please enter a username",
                  }}
                  errorMessage={errors.username && errors.username.message}
                />
              </div>
              <div className="mb-4">
                <TextInput
                  label="First Name"
                  name="firstName"
                  control={control}
                  rules={{
                    required: "Please enter a first name",
                  }}
                  errorMessage={errors.firstName && errors.firstName.message}
                />
              </div>
              <div className="mb-4">
                <TextInput
                  label="Last Name"
                  name="lastName"
                  control={control}
                  rules={{
                    required: "Please enter a last name",
                  }}
                  errorMessage={errors.lastName && errors.lastName.message}
                />
              </div>
              <div className="mb-4">
                <TextInput
                  label="Email"
                  name="email"
                  control={control}
                  rules={{
                    required: "Please enter an email",
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                      message: "invalid email address",
                    },
                  }}
                  errorMessage={errors.email && errors.email.message}
                />
              </div>
              <div className="mb-4">
                <TextInput
                  label="Password"
                  name="password"
                  type="password"
                  control={control}
                  rules={{
                    required: "Please enter a password",
                  }}
                  errorMessage={errors.password && errors.password.message}
                />
              </div>
              <div className="mb-4">
                <TextInput
                  label="Confirm Password"
                  name="confirmPassword"
                  type="password"
                  control={control}
                  rules={{
                    required: "Please confirm password",
                  }}
                  errorMessage={
                    errors.confirmPassword && errors.confirmPassword.message
                  }
                />
              </div>
              <div className="mb-4">
                <SelectBox
                  label="Role"
                  name="role"
                  control={control}
                  rules={{
                    required: "Please select a role",
                  }}
                  errorMessage={errors.role && errors.role.message}
                  options={
                    <>
                      <option value="">Select a role </option>
                      <option value={UserRole.Student}>Student</option>
                      <option value={UserRole.Professor}>Professor</option>
                    </>
                  }
                />
              </div>
              <div className="text-center pt-4">
                <ButtonPrimary type="submit">Create Account</ButtonPrimary>
              </div>
              <div className="text-center">
                <Link
                  className="inline-block mt-4 font-semibold leading-6 text-indigo-600 hover:text-indigo-500"
                  to={"/"}
                >
                  Sign in instead?
                </Link>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Registration;
