import React from "react";
import { isProfessor } from "../../../Utils/helpers";
import OverviewProfessor from "../Professor/Overview";
import OverviewStudent from "../Student/Overview";
import { useAppSelector } from "../../../Redux/hooks";
function Overview() {
  const user = useAppSelector((state: any) => state.user.user);
  return (
    <>{isProfessor(user?.role) ? <OverviewProfessor /> : <OverviewStudent />}</> 
  );
}

export default Overview;
