/* eslint-disable import/no-anonymous-default-export */

/*
*  All Application Routes are defined here
*   - This is a good practice to avoid typos
*/

export default {
    home: "/",
    register: {
        root: "/register/*",
        main: "/",
        verify: "verify",
        addinfo: "addinfo"
    },
    forgotpassword: '/forgot-password',
    dashboard: {
        home: "/dashboard",
        profile: "/dashboard/profile",
        settings: "/dashboard/settings",
        notifications: "/dashboard/notifications",
        professor: {
            courses: "/dashboard/courses",  
            students: "/dashboard/students",
            appointments: "/dashboard/appointments",
            availability: "/dashboard/availability",
            advisory: "/dashboard/advisory", 
        },
        student: {
            classes: "/dashboard/classes",  
            appointments: "/dashboard/appointments",
        }
    }

}