import React,{useEffect} from "react";
import { useSelector, useDispatch } from "react-redux";
import { clearSuccess } from "../../Redux/slices/layout"; 
import { isNilOrEmpty } from '../../Utils/helpers';
import Toast from '../Toast';

function SuccessNotification() {
    const dispatch = useDispatch();
    const success = useSelector((store: any) => store.layout.success);

    useEffect(() => {
        setTimeout(() => {
            dispatch(clearSuccess())
        }
        , 1000);  

    },[success])

    return (
        <div>
            {success && (
                <Toast
                    message={success}
                    show={!isNilOrEmpty(success)}
                    onClose={() => dispatch(clearSuccess())}
                    type="success" 
                />
            )}
        </div>
    );
}

export default SuccessNotification;
