import React from "react";
import { login } from "../../../Api/auth";
import { useForm } from "react-hook-form";
import TextInput from "../../../Components/Form/TextInput";
import { CalendarIcon } from "@heroicons/react/24/outline";
import ButtonPrimary from "../../../Components/Button";
import { Link } from "react-router-dom";
import { setUser } from "../../../Redux/slices/user";
import { useAppDispatch } from "../../../Redux/hooks";
interface Iinputs {
  username: string;
  password: string;
}

function Login() {
  const dispatch = useAppDispatch();
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<Iinputs>();

  const onSubmit = (data: Iinputs) => {
    login(data)
      .then((res) => {
       dispatch(setUser(res.data.user));
      }).catch((err) => {
        console.log(err);
      });
  };

  return (
    <div className="bg-gray-50">
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <CalendarIcon className="mx-auto h-10 w-auto" />
        <h2 className="mt-6 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
          Sign in to your account
        </h2>
      </div> 

      <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-[480px]">
        <div className="bg-white px-6 py-12 shadow sm:rounded-lg sm:px-12">
          <form className="space-y-6" onSubmit={handleSubmit(onSubmit)}>
            <div>
              <TextInput
                label="Username"
                name="username"
                control={control}
                rules={{
                  required: "Required",
                }}
                errorMessage={errors.username && errors.username.message}
              />
            </div>
            <div>
              <TextInput
                label="Password"
                name="password"
                control={control}
                type="password"
                rules={{
                  required: "Required",
                }}
                errorMessage={errors.password && errors.password.message}
              />
            </div>
            <div className="flex items-center justify-between">
              <div className="text-sm leading-6">
                <div className="font-semibold text-indigo-600 hover:text-indigo-500">
                  <Link to={'/forgot-password'}>Forgot your password?</Link>
                </div>
              </div>
            </div>
            <div className="flex justify-center">
              <ButtonPrimary type="submit">Sign In</ButtonPrimary>
            </div>
          </form>
        </div>
        <div className="mt-10 text-center text-sm text-gray-500">
            <div>
                <span>
                    Don't have an account?
                </span>
            </div>
            <div>
                <Link className="font-semibold leading-6 text-indigo-600 hover:text-indigo-500" to={'/register'}>
                    Create an account
                </Link>
            </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
