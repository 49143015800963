import React from 'react';
import './App.scss'
import { store, persistor} from './Redux/store'
import { Provider } from 'react-redux';
import Gate from './Gate';
import { AxiosGlobalSetup } from './Utils/axios';
import { BrowserRouter } from 'react-router-dom';
import ErrorNotification from './Components/ErrorNotification';
import SuccessNotification from './Components/SuccessNotification';
import { PersistGate } from 'redux-persist/integration/react';
import Loader from './Components/Loader';
function App() { 
   AxiosGlobalSetup()
  return (
   <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}> 
    <BrowserRouter>
     <Gate />
    </BrowserRouter> 
      <ErrorNotification />
      <SuccessNotification />
      <Loader /> 
    </PersistGate> 
   </Provider>
  );
}

export default App;
