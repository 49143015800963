import React, { useState, useEffect, useMemo } from "react";
import { useForm } from "react-hook-form";
import ButtonPrimary from "../../../../Components/Button";
import { apiGetStudents } from "../../../../Api/students";
import Table from "../../../../Components/Table";
import { StudentColumns } from "./Columns";
import { ArchiveBoxXMarkIcon } from "@heroicons/react/24/outline";
import TextInput from "../../../../Components/Form/TextInput";

function Students() {
  const [students, setStudents] = useState([]);
  const columns = useMemo(() => StudentColumns, []);
  const { control, handleSubmit, watch } = useForm();

  const getStudents = (search: string = "") => {
    console.log("update", search);
    apiGetStudents(search)
      .then((res) => {
        setStudents(res.data.data.students);
      })
      .catch((err) => {
        console.log(err);
      });
  };


  useEffect(() => {
    const searchValue = watch("Search");
    getStudents(searchValue);
  }, [watch("Search")]);

  return (
    <div>
      <div className="flex justify-between items-center">
        <h1 className="text-2xl font-semibold text-gray-600">Students</h1>
      </div>
      <div>
        <form onSubmit={handleSubmit(()=> console.log(''))}> 
          <div className="w-[35%]">
            <TextInput label="Search" name="Search" control={control} />
          </div>
        </form>
      </div>
      <div className="mt-6">
        {students?.length > 0 ? (
          <Table columns={columns} data={students} />
        ) : (
          <div className="flex flex-col justify-center items-center mt-40">
            <ArchiveBoxXMarkIcon className="h-10 w-10 text-gray-400" />
            <h1 className="font-semibold text-gray-600">No students found</h1>
          </div>
        )}
      </div>
    </div>
  );
}

export default Students;
