import React,{useState} from "react";
import { useForm } from "react-hook-form";
import ButtonPrimary from "../../../../Components/Button";
import TextInput from "../../../../Components/Form/TextInput";
import { Link, useLocation } from "react-router-dom";
import {apiResendCode,apiVerifyCode} from '../../../../Api/auth';
import { useAppDispatch } from "../../../../Redux/hooks";
import { setSuccess } from "../../../../Redux/slices/layout";
import { useNavigate } from "react-router-dom";
interface Iinputs {
  code: number;
  userId: string;
}
//resend code prop with email
function Verify() {
  const [userIdNew, setUserIdNew] = useState<string>('');
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const user = location.state?.user; 
  const message = location.state?.message;
  const email = location.state?.user?.email;
  console.log(email);
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<Iinputs>({
    defaultValues: {
      code: 0,
      userId: user?._id || userIdNew,
    },
  });


  const resendCode = () => {
    if(!email) return console.log('no email'); 
    apiResendCode({email: email}).then((res) => {
      dispatch(setSuccess(res.data.message));
      setUserIdNew(res.data.user._id);
    }).catch((err) => {
      console.log(err);
    });
  }
  const onSubmit = (data: Iinputs) => {
    console.log(data, 'verify');
    apiVerifyCode(data).then((res) => {
      dispatch(setSuccess(res.data.message));
      navigate('/register/addinfo', {state: {user: res.data.user}});
    }).catch((err) => {
      console.log(err); 
    });
  };
  return (
    <div className="bg-gray-50">
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <h2 className="mt-6 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
          Verify your account
        </h2>
      </div>
      <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-[480px]">
        <div className="bg-white px-8 py-20 shadow sm:rounded-lg sm:px-12 text-center">
          <p className="text-sm text-gray-600 bg-green-100 px-4 py-3 rounded-lg">
            {message} 
          </p>
          <form className="space-y-6" onSubmit={handleSubmit(onSubmit)}>
            <div>
              <TextInput
                label="Enter 6 digit code sent to your email"
                name="code"
                control={control}
                rules={{
                  required: "Enter 6 digit code sent to your email",
                  pattern: {
                    value: /^[0-9\b]+$/,
                    message: "Please enter only numbers",
                  }, 
                }}
                
                errorMessage={errors.code && errors.code.message}
              />
            </div>
            <div className="flex justify-center">
              <ButtonPrimary type="submit">Verify</ButtonPrimary>
            </div>
          </form>
          <div className="mt-2">
            <Link
              className="font-semibold leading-6 text-indigo-600 hover:text-indigo-500"
              to={""}
              onClick={resendCode}
            >
              Resend code 
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Verify;
