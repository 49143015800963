import React, { useState, useEffect } from "react";
import Table from "../../../../Components/Table";
import { apiGetAppointments } from "../../../../Api/appointments";
import { AppointmentColumns } from "./Columns";
import { ArchiveBoxXMarkIcon } from "@heroicons/react/24/outline";
import { useAppSelector } from "../../../../Redux/hooks";
import ButtonSelect from "../../../../Components/ButtonSelect";
import { AppointmentTypes } from "../../../../Utils/helpers";
function Appointments() {
  const success = useAppSelector((state) => state.layout.success);
  const columns = React.useMemo(() => AppointmentColumns, []);
  const [appointments, setAppointments] = useState([]);
  const [status, setStatus] = useState(AppointmentTypes);
  const [selected, setSelected] = useState(AppointmentTypes[1]);
  const getAppointments = async () => {
    apiGetAppointments(selected.title)
      .then((res) => {
        setAppointments(res?.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    getAppointments();
  }, [success, selected]);

  return (
    <div>
      <div className="flex justify-between items-center">
        <h1 className="text-2xl font-semibold text-gray-600">Appointments</h1>
        <div className=" ">
          <ButtonSelect
            options={status}
            selected={selected}
            onSelect={(option) => setSelected(option)}
          />
        </div>
      </div>
      <div className="mt-6">
        {Array.isArray(appointments) && appointments.length > 0 ? (
          <Table columns={columns} data={appointments} />
        ) : (
          <div className="flex flex-col justify-center items-center mt-40">
            <ArchiveBoxXMarkIcon className="h-10 w-10 text-gray-400" />
            <h1 className="font-semibold text-gray-600">
              No appointments found
            </h1>
          </div>
        )}
      </div>
    </div>
  );
}

export default Appointments;
