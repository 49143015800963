import dayjs from "dayjs";
import React from "react";

interface Props {
  user: any;
}

function ViewProfile({ user }: Props) {
  const shouldRenderDetails =
    user &&
    (user.birthday || user.grade || user.major || user.minor || user.title);

  return (
    <>
      {shouldRenderDetails && (
        <div className="bg-white max-w-2xl overflow-hidden sm:rounded-lg">
          <div className="px-4 py-4 sm:px-6">
            <div className="w-20 h-20 mb-2 md:mr-4">
              <img
                className="object-cover rounded-full"
                src="https://tailwindflex.com/public/images/user.png"
                alt="avatar"
              />
            </div>
            <h3 className="text-2xl font-medium text-gray-900">
              {user.firstName} {user.lastName}
            </h3>
            <p className="text-md capitalize mt-1">{user.major}</p>
            <p className="text-sm text-gray-500 capitalize">{user.minor}</p>
          </div>
          <div className="border-t border-gray-200">
            <dl className="p-4 mx-2 mb-4">
              {user.birthday && (
                <div className="bg-gray-50 px-4 py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt className="text-sm font-medium text-gray-500">
                    Birthday
                  </dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                    {dayjs(user.birthdate).format("MM-DD-YYYY")}
                  </dd>
                </div>
              )}
              <div className="bg-white px-4 py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">Gender</dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                  {user.gender}
                </dd>
              </div>
              {user.grade && (
                <div className="bg-gray-50 px-4 py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt className="text-sm font-medium text-gray-500">Grade</dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                    {user.grade}
                  </dd>
                </div>
              )}
              <div className="bg-white px-4 py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">Email</dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                  {user.email}
                </dd>
              </div>
              <div className="bg-gray-50 px-4 py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">Created</dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                  {dayjs(user.createdAt).format("MM-DD-YYYY")}
                </dd>
              </div>
            </dl>
          </div>
        </div>
      )}
    </>
  );
}

export default ViewProfile;
