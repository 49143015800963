import React from "react";
import { Button } from "react-aria-components";
type ButtonProps = {
  children: React.ReactNode;
  type?: "button" | "submit" | "reset" | undefined;
  onPress?: () => void;
  isDisabled?: boolean;
  className?: string;
  ariaLabel?: string;
  ariaLabelledBy?: string;
};

function ButtonPrimary(props: ButtonProps) {
  return (
    <div>
      <Button
        className="bg-blue-900 hover:bg-blue-800 text-white font-bold py-2 px-6 rounded-full border-2 border-yellow-500 disabled:opacity-50 disabled:cursor-not-allowed disabled:bg-blue-900 disabled:border-yellow-500"
        {...props}
      >
        {props.children}
      </Button>
    </div>
  );
}

export default ButtonPrimary;

/**
 * Instructions: props to pass in
 * children: React.ReactNode
 * type?:  you can pass in type
 * onPress?: () => void instead of onClick use this
 * isDisabled?: boolean this will disable the button (true)
 * className?: string this will override the default class I have writen
 */
