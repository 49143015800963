import React, { useState, useEffect, useMemo } from "react";
import ButtonPrimary from "../../../../Components/Button";
import { apiGetAvailability } from "../../../../Api/availability";
import Table from "../../../../Components/Table";
import { AvailabilityColumns } from "./Columns";
import { ArchiveBoxXMarkIcon } from "@heroicons/react/24/outline";
import { useAppSelector } from "../../../../Redux/hooks";
import Modal from "../../../../Components/Modal";
import NewAvailability from "./New";

function Availability() {
  const success = useAppSelector((state) => state.layout.success);
  const [newAvailability, setNewAvailability] = useState(false);
  const [availability, setAvailability] = useState([]);
  const columns = useMemo(() => AvailabilityColumns, []);
  const getAvailability = () => {
    apiGetAvailability()
      .then((res) => {
        setAvailability(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    getAvailability();
  }, [success]);

  return (
    <div>
      <Modal show={newAvailability} maxWidth="2xl">
        <NewAvailability onClose={() => setNewAvailability(false)} />
      </Modal>
      <div className="flex justify-between items-center">
        <h1 className="text-2xl font-semibold text-gray-600">Availability</h1>
        <ButtonPrimary onPress={() => setNewAvailability(true)}>
          New
        </ButtonPrimary>
      </div>
      <div className="mt-6">
      {Array.isArray(availability) && availability.length > 0 ? (
          <Table columns={columns} data={availability} />
        ) : (
          <div className="flex flex-col justify-center items-center mt-40">
            <ArchiveBoxXMarkIcon className="h-10 w-10 text-gray-400" />
            <h1 className="font-semibold text-gray-600">
              No Availability found 
            </h1>
          </div>
        )}
      </div>
    </div>
  );
}

export default Availability;
