import { useState } from "react";
import Modal from "../../../../../Components/Modal";
import { EyeIcon } from "@heroicons/react/24/solid";
import ViewStudent from "../View";

export const StudentColumns = [
  {
    Header: "First Name",
    accessor: "firstName",
  },
  {
    Header: "Last Name",
    accessor: "lastName",
  },
  {
    Header: "Grade",
    accessor: "grade",
  },
  {
    Header: "Major(s)",
    accessor: "major",
  },
  {
    Header: "Actions",
    accessor: "actions",
    Cell: ({ row }: any) => {
      const [showModal, setShowModal] = useState(false);

      return (
        <>
          <div className="flex justify-start items-center space-x-4">
            <button onClick={() => setShowModal(true)}>
              <EyeIcon className="h-5 w-5 mr-2 text-gray-500" />
              View
            </button>
          </div>
          {showModal && (
            <Modal show={showModal} onClose={() => setShowModal(false)}>
              <ViewStudent
               student={row.original}
              
              /> 
            </Modal>
          )}
        </>
      );
    },
  },
];
